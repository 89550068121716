import { useState,useEffect } from 'react';

export function useToken() {
    const [token, setToken] = useState(null);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('nabu-user'));
        if (items) {
            setToken(items.token);
        }
    }, []);

    return token;
}

