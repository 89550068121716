import React from 'react';
import './calendar.css';
import PivotNavbar from '../PivotNavbar';
import CalendarTimetable from '../../../components/calendarTimetable';
import Button from '@mui/material/Button';


const Calendar = () => {
    return (
        <div className='mainSectionTitlePivot'>

            <div className='pivot-navbarContainer'>
                <PivotNavbar />
            </div>

            <div className="contentWrapperCalendar">

            <div className='titleMainPageCalendar'>
                <span style={{ borderBottom: '1px solid #757575', display: 'inline-block', width: '100%', padding: '2px', marginBottom: '20px' }}>Program List</span>
                
                <CalendarTimetable />
                <CalendarTimetable />
                <CalendarTimetable />
                <CalendarTimetable />
                <CalendarTimetable />
                <CalendarTimetable />
                <CalendarTimetable />

                </div>

                <div>
            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '300px', marginTop: '60px', marginBottom: '10px', '&:hover': { backgroundColor: '#94b634' } }}>Save & Submit</Button>
            </div>

            </div>
        </div>

    );
};

export default Calendar;
