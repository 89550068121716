import React, { useState } from 'react';
import './sectors.css';
import PivotNavbar from '../PivotNavbar';
import Button from '@mui/material/Button';
import placeholderMap from '../../../assets/placeholdermap.png';
import SectorRow from './SectorRow';


const Sectors = () => {

    return (
        <div className='mainSectionTitlePivot'>
            <div className='pivot-navbarContainer'>
                <PivotNavbar />
            </div>

            <div className="contentWrapperSectors">


                <div className='three-column-container-sectors'>
                    <div className='column'>
                        <div className='titleMainPageSectors'>
                            <span className='setup-title'>Stop in Slot</span>
                        </div>
                        <div className='sectors-item'>
                            <label className='sectors-label'>SIS Angle 1</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>SIS Angle 2</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>SIS Angle 3</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>SIS Angle 4</label>
                            <input type="text" className='input-box-sectors' />
                        </div>
                    </div>

                    <div className='column'>
                        <div className='titleMainPageSectors'>
                            <span className='setup-title'>Auto Reverse/Auto Stop</span>
                        </div>
                        <div className='sectors-item'>
                            <label className='sectors-label'>Real Left Barricade (±4.92º)</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>Virtual Left Barricade</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>Virtual Right Barricade</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>Real Right Barricade (±4.92º)</label>
                            <input type="text" className='input-box-sectors' />
                        </div>

                        <div className='sectors-item'>
                            <label className='sectors-label'>AR/AS delay</label>
                            <input type="text" className='input-box-sectors' />
                        </div>
                    </div>

                    <div className='column'>
                        <div className='map-container'>
                            <img src={placeholderMap} alt='Map' />
                        </div>
                    </div>
                </div>

                <div className='SectorBelowRows'>
                    <div className='titleMainPageSectors'>
                        <div className='setup-title'>Speed Sectors Table</div>
                        <div style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Open Sans', marginLeft: '1%', marginTop: '0.1%', marginBottom: '2%' }}>
                                Pivot Information: 116 m + 32 m 360° 5.54 Ha
                            </div>
                    </div>
                    <SectorRow label="Sector 1" />
                    <SectorRow label="Sector 2" />
                    <SectorRow label="Sector 3" />
                    <SectorRow label="Sector 4" />
                    <SectorRow label="Sector 5" />
                    <SectorRow label="Sector 6" />

                </div>

                <div>
                    <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '450px', marginTop: '30px', marginBottom: '10px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                </div>

            </div>
        </div>
    );
};

export default Sectors;
