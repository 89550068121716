import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

const NotificationItem = ({ date, pivot, temperature, status, message }) => {
  const statusColors = {
    INFO: '#2082A4',
    WARNING: 'orange',
    ERROR: 'red',
    ACTIVITY: '#F5E050',
  };

  const getStatusStyle = (status) => ({
    fontFamily: 'Nunito Sans',
    fontSize: '12px',
    color: 'white',
    backgroundColor: statusColors[status],
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '4px 8px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '80px',
  });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderBottom: '1px solid #ddd',
        gap: '20px',
        
      }}
    >
      <div style={{ flex: 3 }}>{date}</div>
      <div style={{ flex: 1 }}>{pivot}</div>
      <div style={{ flex: 1 }}>{temperature}</div>
      <div style={{ flex: 2 }}>
        <div style={getStatusStyle(status)}>{status}</div>
      </div>
      <div style={{ flex: 3 }}>{message}</div>
    </div>
  );
};

const NotificationsPanel = () => {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedPivot, setSelectedPivot] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [maxHeight, setMaxHeight] = useState('50vh');

  const notifications = [
    { date: 'March 27, 2024 06:18:12', pivot: 'Pivot A', temperature: '14.1°', status: 'ACTIVITY', message: t('With water') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field 1', temperature: '', status: 'INFO', message: t('EOS Data OK') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field 1', temperature: '', status: 'INFO', message: t('EOS Data High') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Field 2', temperature: '', status: 'WARNING', message: t('No Sat Image Available') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field 2', temperature: '', status: 'ERROR', message: t('EOS Data Low') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field 2', temperature: '', status: 'ACTIVITY', message: t('Settings Changed') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field A', temperature: '', status: 'INFO', message: t('Field OK') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field A', temperature: '', status: 'WARNING', message: t('Field too wet') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field A', temperature: '', status: 'WARNING', message: t('Field too dry') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field A', temperature: '', status: 'WARNING', message: t('High Temperature') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field B', temperature: '', status: 'WARNING', message: t('Low Temperature') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field B', temperature: '', status: 'WARNING', message: t('Heavy Rain') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field B', temperature: '', status: 'WARNING', message: t('Rain') },
    { date: 'March 27, 2024 06:18:12', pivot: 'Field A', temperature: '', status: 'WARNING', message: t('Strong Wind') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 1', temperature: '30 cm', status: 'INFO', message: t('New sensor data collected') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 1', temperature: '30 cm', status: 'INFO', message: t('Sensor data on trend') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 1', temperature: '30 cm', status: 'WARNING', message: t('Sensor data different from trend') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 1', temperature: '30 cm', status: 'ERROR', message: t('No data from sensor') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 2', temperature: '50 cm', status: 'ERROR', message: t('Battery Low') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 2', temperature: '50 cm', status: 'ERROR', message: t('Sensor Data Below Treshold') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 2', temperature: '50 cm', status: 'ERROR', message: t('Sensor Data Above Treshold') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Sensor 2', temperature: '50 cm', status: 'ACTIVITY', message: t('Settings Changed') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'INFO', message: t('Pivot started OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'INFO', message: t('Pivot stopped OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'INFO', message: t('Pivot in pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'INFO', message: t('No water') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'INFO', message: t('With water') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'WARNING', message: t('Low pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'WARNING', message: t('High pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'WARNING', message: t('Low flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'WARNING', message: t('High flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ERROR', message: t('Communication Error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ERROR', message: t('Power Error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ERROR', message: t('No water') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ERROR', message: t('Pivot misaligned') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Settings changed') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('System stopped by remote') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('System stopped by field end') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('System stopped by panel') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('System started by panel') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('System started by remote') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Direction forward') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Direction reverse') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Direction changed by user') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Direction changed by panel') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pivot A', temperature: '13.8°', status: 'ACTIVITY', message: t('Direction changed by field end') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'INFO', message: t('Valve 1 started OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'INFO', message: t('Valve 1 stopped OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'INFO', message: t('System in pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'INFO', message: t('Waiting for pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'WARNING', message: t('Low pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'WARNING', message: t('High pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 1', status: 'WARNING', message: t('Low flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 2', status: 'WARNING', message: t('High flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 2', status: 'ERROR', message: t('Communication Error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 2', status: 'ERROR', message: t('Power Error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 2', status: 'ERROR', message: t('No water') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Cont 1', temperature: 'Valve 2', status: 'ACTIVITY', message: t('Settings changed') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'INFO', message: t('Valve 1 started OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'INFO', message: t('Valve stopped OK') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'INFO', message: t('System in pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('Low pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('High pressure') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('Low flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('High flow') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('Low level') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'WARNING', message: t('High level') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'ERROR', message: t('Communication error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'ERROR', message: t('Power error') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 1', temperature: 'Valve 1', status: 'ERROR', message: t('No water') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('Pressure too low') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('Pressure too high') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('Flow too low') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('Flow too high') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('Low level') },
    { date: 'March 27, 2024 06:08:34', pivot: 'Pump 2', temperature: 'Valve 1', status: 'ERROR', message: t('High level') },

  ];

  const filteredNotifications = notifications.filter((notification) => {
    const pivotMatch = selectedPivot ? notification.pivot.includes(selectedPivot) : true;
    const statusMatch = selectedStatus ? notification.status.includes(selectedStatus) : true;
    return pivotMatch && statusMatch;
  });

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      if (windowWidth <= 768) {
        setMaxHeight('72.8vh');
      } else if (windowWidth > 768 && windowHeight <= 900) {
        setMaxHeight('72.8vh');
      } else {
        setMaxHeight('40vh');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleFilter = () => setShowFilter(!showFilter);
  const clearFilters = () => {
    setSelectedPivot('');
    setSelectedStatus('');
  };

  return (
    <div
      style={{
        maxHeight,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #afabab',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        marginBottom: '3%',
      }}
    >
      <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <div
          style={{
            backgroundColor: '#A6CE39',
            color: 'white',
            padding: '4px 30px',
            fontFamily: 'Nunito Sans',
            fontSize: '20px',
            textAlign: 'center',
            borderRadius: '8px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
          }}
        >
          {t('notifications')}
        </div>
        <div style={{ position: 'absolute', right: '10px', cursor: 'pointer' }} onClick={toggleFilter}>
          <SearchIcon style={{ color: '#A6CE39' }} />
        </div>
      </div>

      {showFilter && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
            width: '250px',
            zIndex: 1,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={toggleFilter} style={{ cursor: 'pointer', color: '#A6CE39' }} />
          </div>
          <h3 style={{ fontFamily: 'Open Sans', textAlign: 'center', marginTop: '5px' }}>{t('Filter Options')}</h3>
          <label style={{ fontFamily: 'Nunito Sans', fontSize: '14px' }}>{t('Device Name')}:</label>
          <input
            type="text"
            value={selectedPivot}
            onChange={(e) => setSelectedPivot(e.target.value)}
            placeholder={t('Enter pivot')}
            style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc', fontFamily: 'Open Sans' }}
          />
          <label style={{ fontFamily: 'Nunito Sans', fontSize: '14px' }}>{t('Status')}:</label>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '10px' }}
          >
            <option value="">{t('Select status')}</option>
            <option value="INFO">INFO</option>
            <option value="WARNING">WARNING</option>
            <option value="ERROR">ERROR</option>
            <option value="ACTIVITY">ACTIVITY</option>
          </select>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={clearFilters}
              style={{
                backgroundColor: '#ccc',
                padding: '8px 20px',
                borderRadius: '6px',
                cursor: 'pointer',
                border: 'none',
                fontFamily: 'Nunito Sans',
                fontWeight: 'bold',
              }}
            >
              {t('Clear')}
            </button>
            <button
              onClick={toggleFilter}
              style={{
                backgroundColor: '#A6CE39',
                color: 'white',
                padding: '8px 20px',
                borderRadius: '6px',
                cursor: 'pointer',
                border: 'none',
                fontFamily: 'Nunito Sans',
                fontWeight: 'bold',
              }}
            >
              {t('Apply')}
            </button>
          </div>
        </div>
      )}

      <div style={{ flex: 1, overflowY: 'auto', fontFamily: 'Open Sans', fontSize: '10px', fontWeight: '600' }}>
        {filteredNotifications.map((notification, index) => (
          <NotificationItem key={index} {...notification} />
        ))}
      </div>
    </div>
  );
};

export default NotificationsPanel;


