import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Settings as SettingsIcon, Person as PersonIcon, Close as CloseIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import './sideBar.css';
import ListOfFields from './listOfFields';
import MapIcon from '@mui/icons-material/Map';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FieldIcon from '@mui/icons-material/Agriculture'; // Example sub-group icon
import FmdGoodIcon from '@mui/icons-material/FmdGood';



const iconColor = '#806A50';
const textStyle = {
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 600, // Semi-bold
  fontSize: '12pt',
  color: '#000000', // Set text color if needed
};

const textStyleSG = {
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 600, // Semi-bold
  fontSize: '10pt',
  color: '#000000', // Set text color if needed
};

function Sidebar() {
  const [open, setOpen] = useState(false);
  const [farmsOpen, setFarmsOpen] = useState(false); // State to control "Farms" subgroup
  const [machiaOpen, setMachiaOpen] = useState(false); // State for Machia subgroup
  const [orchardOpen, setOrchardOpen] = useState(false); // State for Orchard subgroup

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleFarmsClick = () => {
    setFarmsOpen(!farmsOpen); // Toggle "Farms" subgroup
  };
  const handleMachiaClick = () => {
    setMachiaOpen(!machiaOpen); // Toggle Machia subgroup
  };
  const handleOrchardClick = () => {
    setOrchardOpen(!orchardOpen); // Toggle Orchard subgroup
  };

  return (
    <div className={`sidebar ${open ? 'open' : 'closed'}`}>
      <div className="sidebar-icons">
        <IconButton 
          edge="start" 
          color="inherit" 
          aria-label="menu" 
          onClick={toggleDrawer(true)}
          className="menu-icon"
        >
          <MenuIcon style={{ color: iconColor, marginLeft: '45%' }} />
        </IconButton>
        <div className="nav-icons">
          <IconButton style={{ color: iconColor }} aria-label="Profile">
            <PersonIcon />
          </IconButton>
          <IconButton style={{ color: iconColor }} aria-label="Farms">
            <MapIcon />
          </IconButton>
          <IconButton style={{ color: iconColor }} aria-label="Devices">
            <DeviceHubIcon />
          </IconButton>
          <IconButton style={{ color: iconColor }} aria-label="Settings">
            <SettingsIcon />
          </IconButton>
        </div>
      </div>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div role="presentation" className="drawerContent">
          <div className="closeButtonContainer">
            <IconButton onClick={toggleDrawer(false)} aria-label="close">
              <CloseIcon style={{ color: iconColor }}/>
            </IconButton>
          </div>
          <List>
            <ListItem button>
              <ListItemIcon>
                <PersonIcon style={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText primary="Profile" primaryTypographyProps={{ style: textStyle }} />
            </ListItem>

            {/* Farms with sub-group */}
            <ListItem button onClick={handleFarmsClick}>
              <ListItemIcon>
                <MapIcon style={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText primary="Farms" primaryTypographyProps={{ style: textStyle }} />
              {farmsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {/* Collapsible Subgroup under Farms */}

            <Collapse in={farmsOpen} timeout="auto" unmountOnExit>
  <List component="div" disablePadding>
    {/* Machia with subgroups */}
    <ListItem button onClick={handleMachiaClick} sx={{ pl: 2, py: 0.5 }}> {/* Reduce padding-left and padding-y */}
      <ListItemIcon>
        <FmdGoodIcon style={{ color: iconColor }} />
      </ListItemIcon>
      <ListItemText primary="Machiya" primaryTypographyProps={{ style: textStyle }} />
      {machiaOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={machiaOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button sx={{ pl: 4, py: 0.2 }}> {/* Reduce spacing between sub-items */}
          <ListItemText primary="CP 1" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 2" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 3" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 4" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 5" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 6" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 7" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 8" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 9" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="CP 10" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
      </List>
    </Collapse>
    {/* Orchard with subgroups */}
    <ListItem button onClick={handleOrchardClick} sx={{ pl: 2, py: 0.5 }}>
      <ListItemIcon>
        <FmdGoodIcon style={{ color: iconColor }} />
      </ListItemIcon>
      <ListItemText primary="Orchard" primaryTypographyProps={{ style: textStyle }} />
      {orchardOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={orchardOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button sx={{ pl: 4, py: 0.2 }}> {/* Reduce spacing between Orchard items */}
          <ListItemText primary="Orchard 1" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="Orchard 2" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="Orchard 3" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="Orchard 4" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
        <ListItem button sx={{ pl: 4, py: 0.2 }}>
          <ListItemText primary="Orchard 5" primaryTypographyProps={{ style: textStyleSG }} />
        </ListItem>
      </List>
    </Collapse>
  </List>
</Collapse>

            <ListItem button>
              <ListItemIcon>
                <DeviceHubIcon style={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText primary="Devices" primaryTypographyProps={{ style: textStyle }} />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon style={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText primary="Settings" primaryTypographyProps={{ style: textStyle }} />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <AddCircleOutlineRoundedIcon style={{ color: iconColor }} />
              </ListItemIcon>
              <ListItemText primary="Add" primaryTypographyProps={{ style: textStyle }} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}

export default Sidebar;
