import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './PivotNavbar.css';
import Modal from './pivotNavbarSections/modal'; // Adjust the path as per your project structure

const PivotNavbar = () => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="pivot-navbar">
            <NavLink exact to="/home/irrigation/" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Status</NavLink>
            <span className="pivot-nav-item" onClick={handleOpenModal}>Charts</span>
            <NavLink to="/home/irrigation/pivota/calendar" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Calendar</NavLink>
            <NavLink to="/home/irrigation/pivota/endgun" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>End Gun</NavLink>
            <NavLink to="/home/irrigation/pivota/sectors" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Sectors</NavLink>
            <NavLink to="/home/irrigation/pivota/alarms" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Alarms</NavLink>
            <NavLink to="/home/irrigation/pivota/setup" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Setup</NavLink>
            <NavLink to="/home/irrigation/pivota/maintenance" className={({ isActive }) => isActive ? 'pivot-nav-item active' : 'pivot-nav-item'}>Maintenance</NavLink>


            {showModal && <Modal handleClose={handleCloseModal} />} {/* Render Modal component when showModal state is true */}
        </div>
    );
};

export default PivotNavbar;
