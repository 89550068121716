import { useEffect, useState } from 'react';

export function useFetchFieldCoordinates(token, id) {
  //console.log(token)
  //console.log(id)
  const [dataFieldCoordinates, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/fieldcor/${id}`, { headers });
        const data = await response.json();
        setData(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token && id) {
      fetchData();
    }
  }, [token, id]); // Dependency array includes id

  return { dataFieldCoordinates, loading, error };
}
