import React from 'react';
import './Timeline.css'; // Make sure the CSS styles are appropriate

const Timeline = ({ events }) => {
    return (
        <div className="timeline-container">
            {events.map((event, index) => (
                <div key={index} className="timeline-item">
                    <div className="timeline-content">
                        <h3>{event.title}</h3> {/* Seeding date as the title */}
                        <h4>{event.cardTitle}</h4> {/* Crop type as card title */}
                        <p>{event.cardSubtitle}</p> {/* Display formatted seeding date */}
                        <p>{event.cardDetailedText}</p> {/* Detailed text like time of entry */}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
