
import React, { useState, useEffect } from 'react';
import './homePageMain.css'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Forward5Icon from '@mui/icons-material/Forward5';
import NotificationsPanel from './notificationNew';
import LightModeIcon from '@mui/icons-material/LightMode';
import MapViewMulti from '../../components/leaFlatFieldMapMulti';
import { Link, useParams } from 'react-router-dom'
import { useFetchFieldCoordinatesMulti } from '../../hooks/fetch_FieldCoordinates_Multi';
import { useToken } from '../../hooks/useToken';
import { useFarmData } from '../../hooks/fetch_apiFarm';
import { useFarmDataList } from '../../hooks/fetch_apiFarmList';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { polygon } from 'leaflet';
import { Box, CircularProgress } from '@mui/material';
import { useDeviceData } from '../../services/useDeviceData';
import image from '../../assets/image111.png'
import { useTranslation } from 'react-i18next';
import CustomBox from './customBoxSolar';
import RefreshIcon from '@mui/icons-material/Refresh'; // Use a Material-UI icon for the refresh
import CountdownTimer from '../../layoutElements/countdown';
import CountdownTimer2 from '../../layoutElements/countdown_2';
import { CheckBox } from '@mui/icons-material';
import PivotStatusDetails from './pivotStatusDetail';
import Left4Element from './pumpSection';
import solarPanel from '../../assets/image111.png'
import waterPump from '../../assets/icon_pump.png'

function VerticalAdjustableElement() {

  const shouldShowPumpOverlay = false;
  const token = useToken()
  //const id   = 9// useParams();
  //console.log("id", id)   
  console.log("token", token)
  const { data: dataList, loading: loadingList, error: errorList } = useFarmDataList(token);
  const id = dataList && dataList.length > 0 ? dataList[0].id : null;


  console.log("farm data list", dataList)
  //const { data, loading, error } = useFarmData(token, id); 
  const [selectedFarm, setSelectedFarm] = useState('');
  const [selectedFarmId, setSelectedFarmId] = useState(null);
  useEffect(() => {
    if (dataList && dataList.length > 0) {
      setSelectedFarm(dataList[0].name); // Default to first farm name
      setSelectedFarmId(dataList[0].id);  // Default to first farm ID
    }
  }, [dataList]);
  const { data, loading, error } = useFarmData(token, selectedFarmId);
  console.log("farm data test", data)
  const handleChange = (event) => {
    const farmName = event.target.value;
    setSelectedFarm(farmName);

    // Find the selected farm object and update the selectedFarmId
    const selectedFarmData = dataList.find(farm => farm.name === farmName);
    if (selectedFarmData) {
      setSelectedFarmId(selectedFarmData.id);  // Trigger refetch by changing the ID
    }
  };


  console.log("farm data", data)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [polygonCoordinatesUp, setPolygonCoordinatesUp] = useState([]);
  const [content, setContent] = useState('');
  const [contentMimic, setContentMimic] = useState("True");
  const handlePolygonClick = (polygon) => {
    setContent(polygon);
  };

  const { dataFieldCoordinatesMulti, loadingCoor, errorCoor } = useFetchFieldCoordinatesMulti(token, selectedFarmId);
  console.log("dataFieldCoordinatesMulti", dataFieldCoordinatesMulti)

  useEffect(() => {
    console.log("only coors initial", dataFieldCoordinatesMulti);

    // Check if data is available and process it
    if (dataFieldCoordinatesMulti && dataFieldCoordinatesMulti.length > 0) {
      const filteredData = dataFieldCoordinatesMulti.filter(fieldData =>

        fieldData.farm_info === data[0].name

      );
      //console.log("data name ",data[0].name)
      console.log("Filtered Data:", filteredData);
      const cornersDataOld = filteredData.map(fieldData =>
        fieldData.coordinateInfo?.[0]?.corners ?? [],


      );

      const cornersData = filteredData.map(fieldData => ({
        coordinates: fieldData.coordinateInfo?.[0]?.corners ?? [],
        fieldInfo: fieldData.field_name // Capture the field_info here  
        //it was field_info to capture field id to show on map
      }));


      console.log("only coors corners", cornersData);

      if (cornersData.length > 0) {
        const newPolygonCoordinates = cornersData.map(polygonData => ({
          coordinates: polygonData.coordinates.map(corner => {
            const key = Object.keys(corner)[0];
            return corner[key];
          }),
          fieldInfo: polygonData.fieldInfo
        }));
        setPolygonCoordinatesUp(newPolygonCoordinates);
        console.log("polygonCoordinatesUp", newPolygonCoordinates)
      }
    }
  }, [dataFieldCoordinatesMulti]);
  const containerStyle = {
    // width: '70vw',
    width: isMobile ? 'auto' : '70vw',
    height: isMobile ? '30vh' : '60vh'
    //width: 'auto',
    //height: '100vh'
    //height: isMobile ? '30vh' : '60vh',
  };

  const solarDevices = [
    {
      id: 1,
      todaysEnergy: '5 kWh',
      power: '3 kW',
      battery: '80%',
      totalEnergy: '50 kWh',
      timestamp: '12/03/2024'
    },
    {
      id: 2,
      todaysEnergy: '6 kWh',
      power: '3.5 kW',
      battery: '85%',
      totalEnergy: '60 kWh',
      timestamp: '12/03/2024'
    },
    {
      id: 3,
      todaysEnergy: '6 kWh',
      power: '3.5 kW',
      battery: '87%',
      totalEnergy: '60 kWh',
      timestamp: '12/03/2024'
    },
    // Add more devices as needed
  ];

  const { t } = useTranslation();

  const navigate = useNavigate();
  const navigateToSatView = (id) => {
    navigate(`/home/sattview/${id}`);
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  /*
    useEffect(() => {
      // Update the time every second
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000); 
  
      // Clean up the timer when the component unmounts
      return () => clearInterval(timer);
    }, []); */

  // Format the date and time in 'DD/MM/YYYY HH:MM:SS' format
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };



  const handleSatelliteClick = () => {
    // Find the object where field_name matches the content
    const selectedField = dataFieldCoordinatesMulti.find(item => item.field_name === content);

    if (selectedField) {
      const fieldInfo = selectedField.field_info; // Get field_info

      // Navigate to the appropriate route
      navigate(`/home/sattview/${fieldInfo}`);
    } else {
      console.error('Field not found for content:', content);
    }
  };


  const { deviceData, loadingDevice, errorDevice } = useDeviceData(token);
  console.log("device data", deviceData)



  const filteredData = dataFieldCoordinatesMulti && deviceData ? dataFieldCoordinatesMulti
    .filter(field => field.farm_info === selectedFarm)  // Filter fields by the selected farm
    .map(field => {
      const devicesForField = deviceData.filter(device => device.field_info === field.field_info);

      return {
        farm_info: field.farm_info,
        field_info: field.field_info,
        field_name: field.field_name,
        devices: devicesForField
      };
    }) : [];

  const [buttonColor, setButtonColor] = useState('grey');  // Initial color is grey (inactive)
  const [isActive, setIsActive] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)
  const [isLoading, setIsLoading] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)

  const handleStartButtom = () => {
    // Step 1: Set loading state (orange)
    setButtonColor('orange');
    setIsLoading(true);  // Set loading to true to show the spinner

    // Step 2: After 2 seconds, toggle between active (green) and inactive (grey)
    setTimeout(() => {
      setIsLoading(false);  // Stop showing the spinner after 2 seconds
      if (isActive) {
        // If currently active, change to inactive (grey)
        setButtonColor('grey');
      } else {
        // If currently inactive, change to active (green)
        setButtonColor('green');
      }
      // Toggle the active/inactive state
      setIsActive(!isActive);
    }, 2000);  // 2 seconds
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '106vh',
        gap: '10px',
        padding: '20px', // Add equal spacing around all sides
        boxSizing: 'border-box', // Ensure padding doesn't overflow
      }}
    >
      {/* Left Section (65% width) with 4 Rows */}
      <div
        style={{
          flex: 60, // 65% of the total width
          display: 'flex',
          flexDirection: 'column',
          gap: '10px', // Add gap between each row
        }}
      >
        {/* Left 1 Element */}
        <div
          style={{
            flex: 0.7,
            //backgroundColor: 'hsl(0, 70%, 90%)', // Different color for each row
            display: 'flex',
            justifyContent: 'flex-start', // Align to the left
            alignItems: 'center',
          }}
        >
          <FormControl
            fullWidth
            sx={{
              //height: '60px',
              width: '100%' // Set the desired height
            }}
          >
            <InputLabel
              sx={{
                fontSize: '0.8rem', // Adjust font size if needed
                lineHeight: '1.2rem', // Adjust line height for better alignment
              }}
            >
              Select Your Farm
            </InputLabel>
            <Select
              value={selectedFarm}
              onChange={handleChange}
              displayEmpty
              label="Select Your Farm"
              fullWidth={false}
              sx={{
                height: '40px', // Set the desired height for the Select component
                fontSize: '14px', // Adjust font size if needed
                '& .MuiSelect-select': {
                  padding: '8px', // Adjust padding for better alignment
                  fontWeight: 'bold', // Bold style for selected farm name
                },
              }}
            >
              {dataList &&
                dataList.map((farm) => (
                  <MenuItem
                    key={farm.id}
                    value={farm.name}
                    sx={{
                      fontWeight: selectedFarm === farm.name ? 'bold' : 'normal',
                    }}
                  >
                    {farm.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

        </div>

        {/* Left 2 Element */}
        <div
          style={{
            flex: 6.3,
            //backgroundColor: 'hsl(90, 70%, 90%)', // Different color for each row
            display: 'flex',
            justifyContent: 'flex-start', // Align to the left
            alignItems: 'center',
          }}
        >
          <MapViewMulti combinedData={filteredData} containerStyle={containerStyle} polygonCoordinatesUp={polygonCoordinatesUp} onClick={() => navigateToSatView(id)} navigateToSatView={navigateToSatView} onPolygonClick={handlePolygonClick} ></MapViewMulti>

        </div>


        {/* Left 3 Element */}

        <div
          style={{
            flex: 2.5,
            //backgroundColor: 'hsl(180, 70%, 90%)',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            padding: '0px',
            height: '100%',
          }}
        >
          {solarDevices.map((device) => (
            <div
              key={device.id}
              style={{
                backgroundColor: 'white',
                border: '1px solid #afabab',
                borderRadius: '10px',
                padding: '10px',
                flex: 1,
                boxSizing: 'border-box',
              }}
            >
              <CustomBox
                id={device.id}
                todaysEnergy={device.todaysEnergy}
                power={device.power}
                battery={device.battery}
                totalEnergy={device.totalEnergy}
                timestamp={device.timestamp}
              />
            </div>
          ))}
        </div>


        {/* Left 4 Element */}
        <div

    style={{
        flex: 2.5,
        //backgroundColor: 'hsl(180, 70%, 90%)',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        padding: '0px',
        height: '100%',
      }}
    >
      {/* First Box */}
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #afabab',
          borderRadius: '10px',
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          boxSizing: 'border-box',
        }}
      >
            {/* Left Section */}
            <div
              style={{
                flex: 1,
                borderRight: '1px solid #afabab',
                padding: '0px',
                paddingLeft: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Title */}
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px'  }}>Autostrada</div>

              {/* Information Section */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{ fontSize: '12px', fontWeight: 600, fontFamily: 'Open Sans' }}>
                  <div>Dir: FWD</div>
                  <div>Angle: 174.5</div>
                  <div>Speed: 100%</div>
                  <div>Rate: 0mm</div>
                  <div>Pres: 0</div>
                  <div>Stop at: 175</div>
                </div>
                <img src={solarPanel} alt="Solar Panel" style={{ width: '65px', height: '65px', marginLeft: "20%" }} />
              </div>

              {/* Timestamp and Refresh Icon */}
              <CountdownTimer>
                <SyncIcon fontSize="small" style={{ marginLeft: '20%' }} />
              </CountdownTimer>
            </div>

            {/* Right Section - Image */}
            <div
              style={{
                flex: 1,
                //borderRight: '1px solid black',
                padding: '0px',
                paddingLeft: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Title */}
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px' }}>Lago</div>

              {/* Information Section */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{ fontSize: '12px', fontWeight: 600, fontFamily: 'Open Sans' }}>
                  <div>Dir: REV</div>
                  <div>Angle: 4</div>
                  <div>Speed: 100%</div>
                  <div>Rate: 0mm</div>
                  <div>Pres: 0</div>
                  <div>Stop at: 120</div>
                </div>
                <img src={solarPanel} alt="Solar Panel" style={{ width: '65px', height: '65px', marginLeft: "20%" }} />
              </div>

              {/* Timestamp and Refresh Icon */}
              <CountdownTimer>
                <SyncIcon fontSize="small" style={{ marginLeft: '20%' }} />
              </CountdownTimer>
            </div>
          </div>

          {/* Second Box */}
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid #afabab',
              borderRadius: '10px',
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              boxSizing: 'border-box',
            }}
          >
            {/* Left Section */}
            <div
              style={{
                flex: 1,
                borderRight: '1px solid #afabab',
                padding: '0px',
                paddingLeft: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Title */}
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px'  }}>Pump A</div>

              {/* Information Section */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{ fontSize: '12px', fontWeight: 600, fontFamily: 'Open Sans' }}>
                  <div>
                    <div style={{ color: 'red' }}>{t("status")}: Off</div>
                    <div>{t("power energia")}: 0.5 kW</div>
                    <div>{t("current")}: 0.2 A</div>
                    <div>{t("hours")}: 0 H</div>
                    <div>{t("pres")}: 0.111 bar</div>
                  </div>
                </div>
                <img src={waterPump} alt="Water Pump" style={{ width: '65px', height: '65px', marginLeft: "15%" }} />
              </div>

              {/* Timestamp and Refresh Icon */}
              <CountdownTimer>
                <SyncIcon fontSize="small" style={{ marginLeft: '20%' }} />
              </CountdownTimer>
            </div>

            {/* Right Section - Image */}
            <div
              style={{
                flex: 1,
                //borderRight: '1px solid black',
                padding: '0px',
                paddingLeft: "10px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Title */}
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px' }}>Pump B</div>

              {/* Information Section */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <div style={{ fontSize: '12px', fontWeight: 600, fontFamily: 'Open Sans' }}>
                  <div>
                    <div style={{ color: 'green' }}>{t("status")}: On</div>
                    <div>{t("power energia")}: 0.5 kW</div>
                    <div>{t("current")}: 0.2 A</div>
                    <div>{t("hours")}: 0 H</div>
                    <div>{t("pres")}: 0.111 bar</div>

                  </div>
                </div>
                <img src={waterPump} alt="Water Pump" style={{ width: '65px', height: '65px', marginLeft: "15%" }} />
              </div>

              {/* Timestamp and Refresh Icon */}
              <CountdownTimer style={{ marginBottom: '5%' }}>
                <SyncIcon fontSize="small" style={{ marginLeft: '20%' }} />
              </CountdownTimer>
            </div>
          </div>
        </div>
      </div>


      {/* Right Section (35% width) with 2 Rows */}

      <div
        style={{
          flex: 35, // 35% of the total width
          display: 'flex',
          flexDirection: 'column',
          gap: '10px', // Add gap between each row
        }}
      >
        {/* Right 1 Element */}
        {/* Right 2 Element */}
        <div
          style={{
            flex: 0.7,
            //  backgroundColor: 'hsl(270, 70%, 80%)', // Different color for each row
            display: 'flex',
            justifyContent: 'center', // Center align
            alignItems: 'center',

          }}
        >
          <p></p>
        </div>
        <div
          style={{
            flex: 6.3,
            
            //backgroundColor: 'hsl(180, 70%, 80%)', // Different color for each row

          }}
        >
          {contentMimic ? (
            <div className='pivotStatusSectionPopUp'>

              <div className='' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ fontSize: '16px', marginTop: '2%', marginLeft: '5%', fontWeight: '900', fontFamily: 'Open Sans', marginRight: '32%' }}>
                  {content} {t("status")}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '3rem' }}>
                  <div style={{ fontSize: '12px', fontWeight: '600', fontFamily: 'Open Sans' }}>
                    Last Access: 20h Ago
                  </div>
                  <SyncIcon fontSize='small' style={{ marginLeft: "5px" }} />
                </div>
              </div>
              <div></div>
              <div className='statusStartStopNameUp-home'>

                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: 'white',       // White background
                    color: 'black',                 // Black text
                    borderColor: 'rgba(0, 0, 0, 0.1)',          // Black outline
                    fontSize: '14px',
                    marginBottom: '20px',
                    marginTop: '0px',
                    marginRight: '4%',
                    margin: '7%',
                    marginLeft: '15%',
                    borderWidth: '1px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',  // Black drop shadow
                    width: '140px', // Set a fixed width for the button
                  }}
                  size="small"
                  onClick={handleSatelliteClick}
                >
                  Satellite
                </Button>

                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: 'white',       // White background
                    color: 'black',                 // Black text
                    borderColor: 'rgba(0, 0, 0, 0.1)',          // Black outline
                    fontSize: '14px',
                    marginBottom: '20px',
                    marginTop: '0px',
                    marginRight: '4%',
                    margin: '7%',
                    marginLeft: '22%',
                    borderWidth: '1px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',  // Black drop shadow
                    width: '140px', // Set a fixed width for the button
                  }}
                  size="small"
                  onClick={handleSatelliteClick}
                >
                  Sensing
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: 'white',       // White background
                    color: 'black',                 // Black text
                    borderColor: 'rgba(0, 0, 0, 0.1)',          // Black outline
                    fontSize: '14px',
                    marginBottom: '20px',
                    marginTop: '0px',
                    marginRight: '4%',
                    margin: '7%',
                    marginLeft: '30%',
                    borderWidth: '1px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',  // Black drop shadow
                    width: '140px', // Set a fixed width for the button
                  }}
                  size="small"
                  onClick={handleSatelliteClick}
                >
                  Irrigation
                </Button>

                <div style={{ backgroundColor: '', color: 'white', fontSize: '14px', marginBottom: '10px', marginTop: '0px', marginRight: '4%', margin: '7%' }}></div>
              </div>
              <div></div>
              
              <div className='statusStartStopNameUp-home'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: buttonColor, color: 'white', fontSize: '14px', marginBottom: '10px', marginTop: '0px', marginRight: '4%', margin: '7%', marginLeft: '15%' }}  // Adjust padding and font size
                  size="small"  // Default small size provided by Material-UI
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={20} color="inherit" />  // Show loading spinner when orange
                    ) : isActive ? (
                      <StopIcon />  // Show Stop icon when green (active)
                    ) : (
                      <PlayArrowIcon />  // Show Play icon when grey (inactive)
                    )
                  }
                  onClick={handleStartButtom}
                >
                  {isActive ? 'Stop' : 'Start'}
                </Button>
                <Button
                  variant="contained"
                  color="error"  // This uses Material-UI's built-in red for the "error" color
                  style={{ color: 'white', fontSize: '14px', marginBottom: '10px', marginTop: '0px', marginRight: '4%', margin: '7%' }}
                  size="small"
                  startIcon={<StopIcon />}
                  onClick={handleSatelliteClick}
                >
                  Stop
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'grey', color: 'white', fontSize: '14px', marginBottom: '10px', marginTop: '0px', marginRight: '4%', margin: '7%' }}  // Adjust padding and font size
                  size="small"  // Default small size provided by Material-UI
                  startIcon={<SettingsBackupRestoreIcon />}
                >
                  Reverse
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'grey', color: 'white', fontSize: '12px', marginBottom: '20px', marginTop: '0px', marginRight: '4%', margin: '7%' }}  // Adjust padding and font size
                  size="small"  // Default small size provided by Material-UI
                  startIcon={<Forward5Icon />}
                  onClick={handleSatelliteClick}
                >
                  Forward
                </Button>
              </div>
              <div></div>
              <div className="pivotStatusDetailTable">
                <div></div>
                <div className="pivotStatusDetailLeft">
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("end pressure")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("actual speed")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("angle")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("pressure control")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("end gun")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("next stop")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("speed set point")}:
                  </div>
                </div>
                <div></div>
                <div className="pivotStatusDetailLeft">
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("wire cut alarm")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("power supply")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("GPS status")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("cellular signal level")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("running hours")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("irrigation hours")}:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '12px', height: '25px' }}>
                    {t("flowmeter")}:
                  </div>
                </div>
                <div></div>
              </div>

            </div>
          ) : (
            <div></div>)}
        </div>

        {/* Right 2 Element */}
        <div
          style={{
            flex: 5,
            //backgroundColor: 'hsl(270, 70%, 80%)',

            maxHeight: '100%', // Adjusted to fit the parent container
            overflow: 'hidden', // Hide any overflow
            maxWidth: '100%'

          }}


        >
          <NotificationsPanel></NotificationsPanel>
        </div>
      </div>
    </div>
  );
}

export default VerticalAdjustableElement;
