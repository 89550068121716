import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh'; // Use a Material-UI icon for the refresh
import LightModeIcon from '@mui/icons-material/LightMode';
import { useTranslation } from 'react-i18next';
import solarPanel from '../../assets/sunSolarPanel1.png';
import CountdownTimer from '../../layoutElements/countdown';

import SyncIcon from '@mui/icons-material/Sync';

function CustomBox({ id,todaysEnergy, power, battery, totalEnergy, timestamp }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%', // Full height of the container
        padding: '0px', // Add some padding
        boxSizing: 'border-box', // Ensure padding is included in the height calculation
      }}
    >
      {/* Title at the top center */}
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        {t("solar device")} {id}
      </div>

      {/* Middle content with info and image */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center content horizontally
          gap: '30px',
          fontSize: '12px',
          fontWeight: "600",
          fontFamily: 'Open Sans',
          flex: 1, // Take up available space
        }}
      >
        {/* Information on the left */}
        <div style={{ textAlign: 'left' }}>
          <div>{t("todays energy")}: {todaysEnergy}</div>
          <div>{t("power")}: {power}</div>
          <div>{t("battery")}: {battery}</div>
          <div>{t("total energy")}: {totalEnergy}</div>
        </div>

        {/* Small image next to the info */}
        <img
          src={solarPanel} // Use the imported image
          alt="Solar Panel"
          style={{ width: '60px', height: '60px', marginLeft:"10%"}}
        />
      </div>

      {/* Time and refresh icon at the bottom right */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          fontSize: '12px',
          fontWeight: '600',
        }}
      >
        <CountdownTimer>
                      <SyncIcon fontSize="small" style={{ marginLeft: '20%' }} />
                    </CountdownTimer>
      </div>
    </div>
  );
}

export default CustomBox;
