import React, { useState, useEffect } from 'react';
import './TodoApp.css'; // Custom CSS file for styling

const TodoApp = () => {
  // Retrieve initial data from localStorage or use hardcoded initialTodos
  const savedTodos = JSON.parse(localStorage.getItem('todos')) || [
    { id: 1, task: "Task 1", status: "todo" },
    { id: 2, task: "Task 2", status: "processing" },
    { id: 3, task: "Task 3", status: "done" },
  ];

  const [todos, setTodos] = useState(savedTodos);
  const [newTask, setNewTask] = useState('');

  // Save tasks to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos));
  }, [todos]);

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const newTodo = { id: todos.length + 1, task: newTask, status: 'todo' };
      setTodos([...todos, newTodo]);
      setNewTask('');
    }
  };

  const handleStatusChange = (id, newStatus) => {
    setTodos(todos.map(todo => (todo.id === id ? { ...todo, status: newStatus } : todo)));
  };

  // Function to download tasks as a .txt file
  const handleDownload = () => {
    const textContent = `const initialTodos = ${JSON.stringify(todos, null, 2)};`;

    const element = document.createElement('a');
    const file = new Blob([textContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'todos.js';  // File with .js extension for easy copying to the IDE
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
  };

  return (
    <div className="todo-app">
      <h1 className="title">My To-Do List</h1>

      <div className="add-task-section">
        <input
          type="text"
          className="task-input"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          placeholder="Add a new task"
        />
        <button onClick={handleAddTask} className="add-btn">Add Task</button>
        <button onClick={handleDownload} className="download-btn">Download Tasks</button>
      </div>

      <div className="columns">
        {/* To-Do Column */}
        <div className="column">
          <h2 className="column-title">To-Do</h2>
          {todos.filter(todo => todo.status === 'todo').map(todo => (
            <div key={todo.id} className="task-item todo-item">
              <span>{todo.task}</span>
              <button className="status-btn" onClick={() => handleStatusChange(todo.id, 'processing')}>Start</button>
            </div>
          ))}
        </div>

        {/* Processing Column */}
        <div className="column">
          <h2 className="column-title">Processing</h2>
          {todos.filter(todo => todo.status === 'processing').map(todo => (
            <div key={todo.id} className="task-item processing-item">
              <span>{todo.task}</span>
              <button className="status-btn" onClick={() => handleStatusChange(todo.id, 'done')}>Complete</button>
            </div>
          ))}
        </div>

        {/* Done Column */}
        <div className="column">
          <h2 className="column-title">Done</h2>
          {todos.filter(todo => todo.status === 'done').map(todo => (
            <div key={todo.id} className="task-item done-item">
              <span>{todo.task}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TodoApp;
