import React, { useState } from 'react';
import { MapContainer, TileLayer, Polygon, Popup, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import GoogleMutantLayer from '../../layoutElements/googleMutant';
import { useNavigate } from 'react-router-dom';
import './sensing.css'
import { useTranslation } from 'react-i18next';

const position = [-13.562635, 27.654236]; // Map center

const positionMoisture = [-13.567, 27.66203]; // Map center

const dotIcon = L.divIcon({
    className: 'gg-data',
    html: '<div class="dot-marker"></div>'
});


const soilIcon = L.divIcon({
    className: 'gg-edit-highlight',
    html: '<div class="dot-marker"></div>'
});


const polygon = [
    [51.509, -0.08],
    [51.503, -0.06],
    [51.51, -0.047],
];

const polygonCoordinates = [
    [-13.56904203976396, 27.66329066722474],
    [-13.56919610610876, 27.66299268941505],
    [-13.5693243402295, 27.66268203182186],
    [-13.56942576614456, 27.66236105882149],
    [-13.56949961190669, 27.66203221331064],
    [-13.56954531547913, 27.66169799811155],
    [-13.5695625290138, 27.66136095692061],
    [-13.56955112149909, 27.66102365494576],
    [-13.56951117975719, 27.66068865938009],
    [-13.56944300778316, 27.66035851986009],
    [-13.56934712443093, 27.66003574905805],
    [-13.56922425946375, 27.65972280355555],
    [-13.56907534799929, 27.65942206514426],
    [-13.56890152339151, 27.65913582269668],
    [-13.56870410860379, 27.65886625474396],
    [-13.56848460613868, 27.65861541289454],
    [-13.5682446866013, 27.65838520621912],
    [-13.56798617598314, 27.65817738672097],
    [-13.56771104176349, 27.65799353600258],
    [-13.5674213779339, 27.65783505322948],
    [-13.56711938906002, 27.65770314448325],
    [-13.56680737350206, 27.65759881358457],
    [-13.56648770592157, 27.65752285445622],
    [-13.56616281920777, 27.65747584508371],
    [-13.56583518596102, 27.65745814311983],
    [-13.56550729967435, 27.65746988316631],
    [-13.56518165575624, 27.6575109757529],
    [-13.56486073253927, 27.65758110802225],
    [-13.56454697241884, 27.65767974611439],
    [-13.56424276326588, 27.65780613923296],
    [-13.56395042025467, 27.65795932536262],
    [-13.56367216824422, 27.65813813859284],
    [-13.56341012484724, 27.65834121799376],
    [-13.56316628431538, 27.65856701797491],
    [-13.56294250236355, 27.65881382004941],
    [-13.56274048204856, 27.6590797459129],
    [-13.56256176080964, 27.65936277173842],
    [-13.56240769876934, 27.65966074357794],
    [-13.56227946838382, 27.65997139375384],
    [-13.56217804552121, 27.66029235811517],
    [-13.56210420203599, 27.66062119402765],
    [-13.56205849989574, 27.66095539896079],
    [-13.56204128690506, 27.66129242953003],
    [-13.56205269405899, 27.66162972085022],
    [-13.56209263454632, 27.66196470605198],
    [-13.56216080441011, 27.66229483581375],
    [-13.56225668486054, 27.66261759775968],
    [-13.56237954622254, 27.6629305355774],
    [-13.56252845348799, 27.66323126770857],
    [-13.56270227343049, 27.66351750547167],
    [-13.56289968322842, 27.66378707047776],
    [-13.56311918053085, 27.66403791120799],
    [-13.56335909488954, 27.66426811862569],
    [-13.5636176004703, 27.66447594070496],
    [-13.56389272994695, 27.66465979576494],
    [-13.56418238947204, 27.66481828450821],
    [-13.56448437461071, 27.66495020067174],
    [-13.56479638711623, 27.66505454020949],
    [-13.56511605241981, 27.66513050893616],
    [-13.56544093770141, 27.66517752857468],
    [-13.56576857040418, 27.66519524116024],
    [-13.56609645705163, 27.66518351176845],
    [-13.5664221022243, 27.66514242954572],
    [-13.56674302755152, 27.6650723070346],
    [-13.56705679057374, 27.66497367779856],
    [-13.56736100333188, 27.66484729236483],
    [-13.56765335054212, 27.66469411251528],
    [-13.56793160721784, 27.66451530396953],
    [-13.56819365560461, 27.66431222751524],
    [-13.56843750129914, 27.66408642865327],
    [-13.56866128842969, 27.66383962583653],
    [-13.56886331378206, 27.66357369739186],
    [-13.56904203976396, 27.66329066722474]
];

const secondPolygonCoordinates = [
    [-13.56639068103621, 27.65604765749],
    [-13.56654468852267, 27.65575454952696],
    [-13.56667327633168, 27.65544874868547],
    [-13.56677546579038, 27.65513258237572],
    [-13.5668504791408, 27.65480845690913],
    [-13.56689774546007, 27.65447883918206],
    [-13.56691690500633, 27.6541462378979],
    [-13.56690781195707, 27.65381318447098],
    [-13.56687053551912, 27.65348221375729],
    [-13.56680535940183, 27.65315584475918],
    [-13.56671277965755, 27.65283656145075],
    [-13.56659350090558, 27.65252679386995],
    [-13.5664484309687, 27.65222889962142],
    [-13.56627867396288, 27.65194514593123],
    [-13.56608552189285, 27.65167769238974],
    [-13.56587044481766, 27.65142857451407],
    [-13.56563507966092, 27.65119968825564],
    [-13.56538121775111, 27.65099277557046],
    [-13.56511079118672, 27.65080941116184],
    [-13.56482585813014, 27.65065099049693],
    [-13.56452858714215, 27.65051871918769],
    [-13.56422124067645, 27.65041360381746],
    [-13.5639061578597, 27.6503364442828],
    [-13.56358573668832, 27.65028782770851],
    [-13.56326241577755, 27.65026812398262],
    [-13.56293865580151, 27.65027748294475],
    [-13.56261692076588, 27.65031583324916],
    [-13.56229965925536, 27.65038288291156],
    [-13.5619892857989, 27.65047812153463],
    [-13.56168816249435, 27.65060082419586],
    [-13.56139858103244, 27.65075005696752],
    [-13.56112274525681, 27.65092468402723],
    [-13.56086275439285, 27.65112337630423],
    [-13.56062058707289, 27.65134462159609],
    [-13.56039808627925, 27.65158673607863],
    [-13.56019694531983, 27.65184787712109],
    [-13.56001869494273, 27.65212605730955],
    [-13.55986469168802, 27.65241915957155],
    [-13.55973610756528, 27.65272495328678],
    [-13.55963392113537, 27.6530411112616],
    [-13.55955891006425, 27.65336522743779],
    [-13.55951164520549, 27.6536948352013],
    [-13.55949248625662, 27.65402742615152],
    [-13.55950157902198, 27.65436046918805],
    [-13.55953885430336, 27.65469142977065],
    [-13.55960402842656, 27.65501778920475],
    [-13.55969660539982, 27.65533706380688],
    [-13.55981588068803, 27.65564682380366],
    [-13.55996094657366, 27.65594471182081],
    [-13.56013069906383, 27.65622846082164],
    [-13.56032384629102, 27.6564959113583],
    [-13.56053891834332, 27.6567450280049],
    [-13.56077427844976, 27.65697391484741],
    [-13.56102813543528, 27.65718082991211],
    [-13.56129855735104, 27.65736419842333],
    [-13.56158348617587, 27.65752262478926],
    [-13.56188075347759, 27.65765490322476],
    [-13.5621880969145, 27.65776002692992],
    [-13.56250317745197, 27.65783719575497],
    [-13.56282359716281, 27.65788582229279],
    [-13.56314691747621, 27.65790553635263],
    [-13.56347067773614, 27.65789618778094],
    [-13.56379241392835, 27.65785784760764],
    [-13.56410967743295, 27.65779080750923],
    [-13.56442005366043, 27.65769557759243],
    [-13.56472118042874, 27.65757288251526],
    [-13.56501076594191, 27.65742365597495],
    [-13.56528660623318, 27.65724903360459],
    [-13.56554660193998, 27.65705034433248],
    [-13.56578877428283, 27.65682910026964],
    [-13.56601128012678, 27.65658698520303],
    [-13.56621242601048, 27.65632584178106],
    [-13.56639068103621, 27.65604765749]
];
const triangleSize = 0.001;
const triangleCoordinatesUp = [[
    [-13.562635, 27.654236],
    [-13.563122, 27.653675],
    [-13.563490, 27.654403],]]

const triangleCoordinatesSoil = [[
    [-13.563189, 27.660781],
    [-13.563598, 27.660322],
    [-13.563946, 27.660820],
    [-13.563524, 27.661317],]]
const center = [-13.56544093770141, 27.66495020067174];
//const center = [-13.56544093770141, 27.66495020067174];

function MapWS({ onPolygonClick }) {
    const [showPopup, setShowPopup] = useState(false);

    const navigate = useNavigate();
    // Click handler for the polygon
    const handlePolygonClick = () => {
        navigate('satellite');
    };

    const handleMarkerClick = () => {
        onPolygonClick('weather_X');
    };

    const handleMarkerClickSoil = () => {
        onPolygonClick('soil_moisture_X');
    };

    const centroid = [
        (polygon[0][0] + polygon[1][0] + polygon[2][0]) / 3,
        (polygon[0][1] + polygon[1][1] + polygon[2][1]) / 3
    ];

    // Define coordinates for the triangle around the centroid
    //const triangleSize = 0.001; // Adjust size as needed
    const triangleCoordinates = [
        [
            [centroid[0] - triangleSize, centroid[1] - triangleSize],
            [centroid[0], centroid[1] + triangleSize],
            [centroid[0] + triangleSize, centroid[1] - triangleSize]
        ]
    ];

    const { t } = useTranslation();
    return (
        <MapContainer center={center} zoom={14} style={{ height: '100%', width: '100%', margin: '5px' }}>
            <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
      />
            {/*<TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    /> */}

            <Marker position={position} icon={dotIcon} eventHandlers={{ click: handleMarkerClick }} >
                <Popup><div>
                    <strong>{t('Weather Station')} X</strong>
                </div></Popup>
            </Marker>

            <Marker position={positionMoisture} icon={soilIcon} eventHandlers={{ click: handleMarkerClickSoil }} >
                <Popup><div>
                    <strong>{t("Soil Moisture")} X</strong>
                </div></Popup>
            </Marker>

            <Polygon
                pathOptions={{ color: 'blue' }}
                positions={polygonCoordinates}
                eventHandlers={{
                    click: handlePolygonClick,
                    mouseover: (e) => {
                        e.target.openPopup();
                    },
                    mouseout: (e) => {
                        e.target.closePopup();
                    }
                }}
            >
                <Popup>
                    <div>
                        <strong>{t("Field")} A</strong>
                    </div>
                </Popup>
            </Polygon>
            <Polygon
                pathOptions={{ color: 'red' }} // Different color for the second polygon
                positions={secondPolygonCoordinates}
                eventHandlers={{
                    click: handlePolygonClick,
                    mouseover: (e) => {
                        e.target.openPopup();
                    },
                    mouseout: (e) => {
                        e.target.closePopup();
                    }
                }}>
                <Popup>
                    <strong>{t("Field")} B</strong></Popup>
            </Polygon>
                    {/*
            <Polygon
                pathOptions={{ color: 'yellow' }} // Different color for the second polygon
                positions={triangleCoordinatesSoil}
                eventHandlers={{
                    click: () => onPolygonClick('soil_moisture_X'),
                    mouseover: (e) => {
                        e.target.openPopup();
                    },
                    mouseout: (e) => {
                        e.target.closePopup();
                    }
                }}>
                <Popup><strong>Soil Moisture XXX</strong></Popup>
            </Polygon> */}

        </MapContainer>
    );
}

export default MapWS;
