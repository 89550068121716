import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Polygon, Popup, useMap, Marker } from 'react-leaflet';
import L from 'leaflet';
import GoogleMutantLayer from '../layoutElements/googleMutant';
import '.././App.css';
import weatherStationIconUrl from '../assets/icon1-weather.png';
import moistureIconUrl from '../assets/icon_SM.png';


function SetViewToBounds({ polygonCoordinatesUp }) {
  const map = useMap();
  useEffect(() => {
    if (polygonCoordinatesUp.length > 0) {
      const bounds = new L.LatLngBounds(polygonCoordinatesUp.map(polygon => polygon.coordinates).flat());
      map.fitBounds(bounds);
    }
  }, [map, polygonCoordinatesUp]);

  return null;
}

const deviceIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowSize: [41, 41],
  shadowAnchor: [12, 41]
});

const moistureIcon = L.icon({
  iconUrl: moistureIconUrl, // Replace with the actual path to the moisture sensor icon
  iconSize: [25, 25], // Size of the icon
  iconAnchor: [12, 25], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -25], // Point from which the popup should open relative to the iconAnchor
  className: 'white-icon'
});

const weatherStationIcon = L.icon({
  iconUrl: weatherStationIconUrl, // Replace with the actual path to the weather station icon
  iconSize: [25, 25],
  iconAnchor: [12, 25],
  popupAnchor: [0, -25],
  className: 'white-icon'
});

function MapViewMulti({ navigateToSatView, polygonCoordinatesUp, onPolygonClick, combinedData }) {
  const mapRef = useRef(null);
  console.log("device map", combinedData)

  return (
    <MapContainer
      center={[0, 0]} // Initial center is irrelevant because bounds will dictate the view
      zoom={15}
      style={{ height: '100%', width: '100%', margin: '5px', maxHeight: '90vh' }}
      ref={mapRef}
      whenCreated={mapInstance => {
        mapRef.current = mapInstance;
      }}
    > {/* 
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      */}
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
      />
      {polygonCoordinatesUp && polygonCoordinatesUp.map((polygon, index) => (
        <Polygon
          key={index}
          pathOptions={{ color: 'green' }}
          positions={polygon.coordinates}
          eventHandlers={{
            //click: () => navigateToSatView(polygon.fieldInfo),
            click: () => {
              // Log the fieldInfo to debug
              console.log('Polygon fieldInfo:', polygon.fieldInfo);
              console.log('Type of polygon.fieldInfo:', typeof polygon.fieldInfo);

              // Convert to string if it's not already
              onPolygonClick(String(polygon.fieldInfo));
            },
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              e.target.closePopup();
            }
          }}
        >
          <Popup>
            <div className="custom-popup">
              <strong>Field {polygon.fieldInfo}</strong>
            </div>
          </Popup>
        </Polygon>
      ))}
      {combinedData.map((field, index) => (
        field.devices.map((device, deviceIndex) => {
          // Determine the icon based on the device type
          const icon = device.device_type === 'moisture' ? moistureIcon : weatherStationIcon;

          return (
            <Marker
              key={`${index}-${deviceIndex}`}
              position={[device.coordinates.lat, device.coordinates.lng]}
              icon={icon} // Apply the custom icon
            >
              <Popup>
                <div>
                  <span style={{ display: 'block',  fontWeight: '600', fontFamily: 'Open Sans'  }}>Device:</span>
                  <span style={{ display: 'block', fontFamily: 'Open Sans' }}>{device.name}</span>
                  <span style={{ display: 'block',  fontWeight: '600', fontFamily: 'Open Sans' }}>Type:</span>
                  <span style={{ display: 'block', fontFamily: 'Open Sans' }}>{device.device_type}</span>
                </div>
              </Popup>

            </Marker>
          );
        })
      ))}
      <SetViewToBounds polygonCoordinatesUp={polygonCoordinatesUp} />
    </MapContainer>
  );
}

export default MapViewMulti;
