import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from "dayjs";
Chart.register(...registerables);

const LineChartSat = () => {
    const { t } = useTranslation();

    const data = {
        labels: [
            t("January"),
            t("February"),
            t("March"),
            t("April"),
            t("May"),
            t("June"),
            t("July")
        ],
        datasets: [
            {
                label: t('Actual Vegetation'),
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: 'rgb(60, 179, 113)',
                borderColor: 'rgb(60, 179, 113)',
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    const xLabels = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];
    

 
    return  <div>
    <LineChart
      xAxis={[
        {
          scaleType: 'point',
          data: xLabels,
          grid: {
            stroke: 'rgba(0, 0, 0, 0.2)', // Lighter transparent lines
            lineDash: [5, 5], // Dashed grid lines
          },
        },
      ]}
      yAxis={[
        {
          grid: {
            stroke: 'rgba(0, 0, 0, 0.2)', // Lighter transparent lines
            lineDash: [5, 5], // Dashed grid lines
          },
        },
      ]}
      series={[
        {
          data: [65, 59, 80, 81, 56, 55, 40, 52, 50, 55, 60, 47],
          color: 'rgb(60, 179, 113)', // Ensure the graph line is not overpowering the grid
        },
      ]}
      width={800}
      height={300}
    />
  </div>
    
    ;
};

export default LineChartSat;