import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';
Chart.register(...registerables);

const LineChartHum = () => {
    const { t } = useTranslation();

    const data = {
        labels: [
            t("January"),
            t("February"),
            t("March"),
            t("April"),
            t("May"),
            t("June"),
            t("July")
        ],
        datasets: [
            {
                label: t('humidity'),
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: 'rgb(60, 179, 113)',
                borderColor: 'rgb(60, 179, 113)',
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return <Line data={data} options={options} />;
};

export default LineChartHum;