import { useState, useEffect } from 'react';

export const useDeviceData = (token) => {
  const [deviceData, setDeviceData] = useState(null);
  const [loadingDevice, setLoadingDevice] = useState(false);
  const [errorDevice, setErrorDevice] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoadingDevice(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/devices/`, { headers });
        const data = await response.json();
        console.log("device data", data);
        setDeviceData(data);
      } catch (e) {
        setErrorDevice(e);
      } finally {
        setLoadingDevice(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);

  return { deviceData, loadingDevice, errorDevice };
};