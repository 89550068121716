import logo from '../assets/logo_header.png';
import "./navbar.css";
import logoText from '../assets/nabuLogoText.png'
import LanguageSelector from '../hooks/langSelector';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

function Navbar() {

    const { t } = useTranslation();
    return (
        <nav className="navbar">
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="logoText">
                <img src={logoText} alt="text logo" ></img>
            </div>
            <div className="navbar-texts">
                <Link to="/home/" className="navbar-link">{t('home')}</Link>
                <Link to="/home/sensing" className="navbar-link">{t('sensing')}</Link>
                <Link to="/home/sattview" className="navbar-link">{t('satellite')}</Link>
                <Link to="/home/irrigation" className="navbar-link">{t('irrigation')}</Link>
                <Link to="/home/pumps" className="navbar-link">{t('pumps')}</Link>
                <Link to="/home/solar" className="navbar-link">{t('solar')}</Link>
                <Link to="/home/slurry-and-waste" className="navbar-link">{t('slurry and waste')}</Link>
            </div>
            <LanguageSelector></LanguageSelector>

            <div className="logOutIcon">
                <div style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    fontFamily: 'Nunito Sans',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center'  // Ensures the text and icon are vertically aligned together
                }}>
                    {t('logout')}
                    <LogoutIcon fontSize="small" style={{ marginLeft: "20%" }} />
                </div>
            </div>


        </nav>
    );
}
export default Navbar;