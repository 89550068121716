// useFarmData.js
import { useEffect, useState } from 'react';

export function useFarmData(token, id) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/farms/`, { headers });
        const data = await response.json();
        setData(data);
        if (data) {
          setData(data.filter(item => item.id === parseInt(id)));
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token, id]);

  //console.log(data)

  return { data, loading, error };
}
