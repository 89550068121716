import React from 'react';
import './satellite.css'


const GradientLine = () => {

    const colorMap = {
        ndvi: 'linear-gradient(to right, rgba(215, 25, 28, 1) 0%, rgba(223, 55, 42, 1) 5%, rgba(230, 85, 56, 1) 10%, rgba(238, 114, 69, 1) 15%, rgba(245, 144, 83, 1) 20%, rgba(252, 171, 98, 1) 25%, rgba(253, 189, 116, 1) 30%, rgba(254, 206, 134, 1) 35%, rgba(254, 223, 153, 1) 40%, rgba(254, 238, 171, 1) 45%, rgba(250, 249, 185, 1) 50%, rgba(237, 247, 184, 1) 55%, rgba(221, 241, 180, 1) 60%, rgba(205, 235, 175, 1) 65%, rgba(187, 227, 170, 1) 70%, rgba(169, 219, 166, 1) 75%, rgba(145, 203, 169, 1) 80%, rgba(120, 185, 173, 1) 85%, rgba(94, 167, 177, 1) 90%, rgba(69, 149, 182, 1) 95%, rgba(43, 131, 186, 1) 100%)', // NDVI color map
        ndre: 'linear-gradient(to right, rgba(0, 0, 4, 1) 0%, rgba(7, 6, 28, 1) 5%, rgba(21, 14, 55, 1) 10%, rgba(38, 17, 86, 1) 15%, rgba(59, 15, 111, 1) 20%, rgba(80, 18, 123, 1) 25%, rgba(100, 26, 128, 1) 30%, rgba(120, 34, 130, 1) 35%, rgba(140, 41, 129, 1) 40%, rgba(161, 48, 126, 1) 45%, rgba(182, 55, 122, 1) 50%, rgba(202, 62, 114, 1) 55%, rgba(221, 74, 105, 1) 60%, rgba(237, 89, 96, 1) 65%, rgba(246, 111, 92, 1) 70%, rgba(251, 135, 97, 1) 75%, rgba(254, 159, 109, 1) 80%, rgba(254, 183, 125, 1) 85%, rgba(254, 206, 145, 1) 90%, rgba(253, 230, 167, 1) 95%, rgba(255, 255, 255, 1) 100%)',   // NDRE color map
        ndmi: 'linear-gradient(to right, rgba(247, 251, 255, 1) 0%, rgba(237, 245, 252, 1) 5%, rgba(228, 239, 249, 1) 10%, rgba(218, 233, 246, 1) 15%, rgba(209, 226, 243, 1) 20%, rgba(200, 220, 240, 1) 25%, rgba(186, 214, 235, 1) 30%, rgba(170, 207, 229, 1) 35%, rgba(154, 200, 224, 1) 40%, rgba(134, 189, 220, 1) 45%, rgba(115, 178, 216, 1) 50%, rgba(98, 168, 210, 1) 55%, rgba(82, 157, 204, 1) 60%, rgba(66, 146, 198, 1) 65%, rgba(53, 133, 191, 1) 70%, rgba(41, 121, 185, 1) 75%, rgba(29, 108, 177, 1) 80%, rgba(18, 94, 166, 1) 85%, rgba(8, 81, 156, 1) 90%, rgba(8, 64, 131, 1) 95%, rgba(0, 0, 0, 1) 100%)'   // NDMI color map
        // Add more color maps for other index types if needed
    };
    return (
        <div className="gradient-line-container">
        <div className="gradient-line" style={{ background: colorMap.ndvi }}></div>
    </div>
    );
};

export default GradientLine;   
