import React, { useState } from 'react';
//import BadgeOutlinedIcon from '@material-ui/icons/BadgeOutlined'; // Import the search icon and any other necessary dependencies
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BorderStyleRoundedIcon from '@mui/icons-material/BorderStyleRounded';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    marginBottom: 5,
    border: '1px solid #ccc',  
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(2, 1, 2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30ch',
      },
    },
  }));

  const SearchInput = ({ initialValue, onValueChange }) => {
    const [username, setUsername] = useState(initialValue || '');
  
    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setUsername(newValue);
        if (onValueChange) {
          onValueChange(newValue);
        }
      };
  
    return (
      <Search>
        <SearchIconWrapper>
          <BorderStyleRoundedIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={username}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          id="fieldname"
          value={username}
          
        />
        <span style={{ marginLeft: '-30px', marginRight:'10px' }}>ha</span>   
      </Search>
    );
  };
  
  export default SearchInput;
  
