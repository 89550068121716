import React, { useState } from 'react';
import { MapContainer, TileLayer, Polygon, Polyline, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Polygon coordinates (circular-like shape)
const polygonCoordinates = [
  [-13.56904203976396, 27.66329066722474],
  [-13.56919610610876, 27.66299268941505],
  [-13.5693243402295, 27.66268203182186],
  [-13.56942576614456, 27.66236105882149],
  [-13.56949961190669, 27.66203221331064],
  [-13.56954531547913, 27.66169799811155],
  [-13.5695625290138, 27.66136095692061],
  [-13.56955112149909, 27.66102365494576],
  [-13.56951117975719, 27.66068865938009],
  [-13.56944300778316, 27.66035851986009],
  [-13.56934712443093, 27.66003574905805],
  [-13.56922425946375, 27.65972280355555],
  [-13.56907534799929, 27.65942206514426],
  [-13.56890152339151, 27.65913582269668],
  [-13.56870410860379, 27.65886625474396],
  [-13.56848460613868, 27.65861541289454],
  [-13.5682446866013, 27.65838520621912],
  [-13.56798617598314, 27.65817738672097],
  [-13.56771104176349, 27.65799353600258],
  [-13.5674213779339, 27.65783505322948],
  [-13.56711938906002, 27.65770314448325],
  [-13.56680737350206, 27.65759881358457],
  [-13.56648770592157, 27.65752285445622],
  [-13.56616281920777, 27.65747584508371],
  [-13.56583518596102, 27.65745814311983],
  [-13.56550729967435, 27.65746988316631],
  [-13.56518165575624, 27.6575109757529],
  [-13.56486073253927, 27.65758110802225],
  [-13.56454697241884, 27.65767974611439],
  [-13.56424276326588, 27.65780613923296],
  [-13.56395042025467, 27.65795932536262],
  [-13.56367216824422, 27.65813813859284],
  [-13.56341012484724, 27.65834121799376],
  [-13.56316628431538, 27.65856701797491],
  [-13.56294250236355, 27.65881382004941],
  [-13.56274048204856, 27.6590797459129],
  [-13.56256176080964, 27.65936277173842],
  [-13.56240769876934, 27.65966074357794],
  [-13.56227946838382, 27.65997139375384],
  [-13.56217804552121, 27.66029235811517],
  [-13.56210420203599, 27.66062119402765],
  [-13.56205849989574, 27.66095539896079],
  [-13.56204128690506, 27.66129242953003],
  [-13.56205269405899, 27.66162972085022],
  [-13.56209263454632, 27.66196470605198],
  [-13.56216080441011, 27.66229483581375],
  [-13.56225668486054, 27.66261759775968],
  [-13.56237954622254, 27.6629305355774],
  [-13.56252845348799, 27.66323126770857],
  [-13.56270227343049, 27.66351750547167],
  [-13.56289968322842, 27.66378707047776],
  [-13.56311918053085, 27.66403791120799],
  [-13.56335909488954, 27.66426811862569],
  [-13.5636176004703, 27.66447594070496],
  [-13.56389272994695, 27.66465979576494],
  [-13.56418238947204, 27.66481828450821],
  [-13.56448437461071, 27.66495020067174],
  [-13.56479638711623, 27.66505454020949],
  [-13.56511605241981, 27.66513050893616],
  [-13.56544093770141, 27.66517752857468],
  [-13.56576857040418, 27.66519524116024],
  [-13.56609645705163, 27.66518351176845],
  [-13.5664221022243, 27.66514242954572],
  [-13.56674302755152, 27.6650723070346],
  [-13.56705679057374, 27.66497367779856],
  [-13.56736100333188, 27.66484729236483],
  [-13.56765335054212, 27.66469411251528],
  [-13.56793160721784, 27.66451530396953],
  [-13.56819365560461, 27.66431222751524],
  [-13.56843750129914, 27.66408642865327],
  [-13.56866128842969, 27.66383962583653],
  [-13.56886331378206, 27.66357369739186],
  [-13.56904203976396, 27.66329066722474],
];

// Line from the center to the edge of the circle
const center = [-13.56583093770141, 27.66155020067174]; // Approximate center of the circle
const radiusLineCoordinates = [
  center, // Start at center
  [-13.5636176004703, 27.66447594070496], // Edge of the circle (adjust this as needed)
];

function MapView({ onPolygonClick }) {
  return (
    <MapContainer center={center} zoom={14} style={{ height: '100%', width: '100%', margin: '5px' }}>
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
      />

      {/* Draw the polygon (circle-like shape) */}
      <Polygon
        pathOptions={{ color: 'blue' }}
        positions={polygonCoordinates}
        eventHandlers={{
          click: () => onPolygonClick('A'),
          mouseover: (e) => {
            e.target.openPopup();
          },
          mouseout: (e) => {
            e.target.closePopup();
          }
        }}
      >
        <Popup>
          <div>
            <strong>Field A</strong>
            <p>- Pivot A</p>
            <p>- Sensor A</p>
          </div>
        </Popup>
      </Polygon>

      {/* Add a green radius line */}
      <Polyline pathOptions={{
    color: '#00FF00', // Neon green color
    weight: 5, // Make the line thicker
    opacity: 0.9, // Increase opacity to make it stand out
    
    lineCap: 'round', // Rounded edges
    shadowColor: '#00FF00', // Same neon green shadow
    shadowBlur: 10, // Create a glowing effect
    shadowOffset: [0, 0], // Center the shadow to make it look like a glow
  }} positions={radiusLineCoordinates} />
    </MapContainer>
  );
}

export default MapView;
