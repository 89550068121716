import React from 'react';
import './../pages/irrigationPage/pivotNavbarSections/calendar.css';

const   CalendarTimetable = () => {
    return (
        <div className='controls-container'>
            <select>
                <option value="option1">None</option>
                <option value="option2">Stop</option>
                <option value="option2">Start</option>
            </select>
            <select style={{ width: '120px', backgroundColor: '#757575' }}>
                <option value="option1">None</option>
                <option value="option2">Stop</option>
                <option value="option2">Start</option>
            </select>

            <div className='checkbox-container'>
                <input type="checkbox" id="sunCheckbox" />
                <label htmlFor="sunCheckbox">Sun.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="monCheckbox" />
                <label htmlFor="monCheckbox">Mon.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="tueCheckbox" />
                <label htmlFor="tueCheckbox">Tue.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="wedCheckbox" />
                <label htmlFor="wedCheckbox">Wed.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="thuCheckbox" />
                <label htmlFor="thuCheckbox">Thu.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="friCheckbox" />
                <label htmlFor="friCheckbox">Fri.</label>
            </div>
            <div className='checkbox-container'>
                <input type="checkbox" id="satCheckbox" />
                <label htmlFor="satCheckbox">Sat.</label>
            </div>
        </div>
    );
};

export default CalendarTimetable;
