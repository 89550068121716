import React from 'react';
import './alarms.css';
import PivotNavbar from '../PivotNavbar';
import Button from '@mui/material/Button';
import AlarmLevelsCheckbox from '../../../components/alarmLevelsCheckbox';

const Alarms = () => {
    return (

        <div className='mainSectionTitlePivot'>

            <div className='pivot-navbarContainer'>
                <PivotNavbar />
            </div>

            <div className="contentWrapperAlarms">
                <div className='titleMainPageAlarms'>
                    <span style={{ borderBottom: '1px solid #757575', display: 'inline-block', width: '100%', padding: '2px', marginBottom: '20px' }}>Alarm Levels</span>
                </div>

                <div className="alarm-levels-container">
                    <AlarmLevelsCheckbox labelText="Stop" />
                    <AlarmLevelsCheckbox labelText="Start" />
                    <AlarmLevelsCheckbox labelText="Direction" />
                    <AlarmLevelsCheckbox labelText="Speed Setpoint" />
                    <AlarmLevelsCheckbox labelText="With Water" />
                    <AlarmLevelsCheckbox labelText="Without Water" />
                    <AlarmLevelsCheckbox labelText="Low Pressure (>30min)" />
                    <AlarmLevelsCheckbox labelText="End Gun" />
                    <AlarmLevelsCheckbox labelText="Power Supply" />
                    <AlarmLevelsCheckbox labelText="GPS Signal" />
                    <AlarmLevelsCheckbox labelText="Communication Lost" />
                    <AlarmLevelsCheckbox labelText="Wire Cut Alarm" />
                    <AlarmLevelsCheckbox labelText="Slip Detection" />
                </div>

                <div className='titleSmallAlarms'>
                    <span style={{ borderBottom: '1px solid #757575', display: 'inline-block', width: '100%', padding: '2px', marginBottom: '20px', marginTop: '20px' }}>Email Notifications</span>
                    <div className='button-container'>
                        <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#B7B7B7', width: '140px', height: '30px', marginLeft: '40px', '&:hover': { backgroundColor: '#6b6b6b' } }}>+ Add Email</Button>
                    </div>

                    <span style={{ borderBottom: '1px solid #757575', display: 'inline-block', width: '100%', padding: '2px', marginBottom: '20px', marginTop: '20px' }}>SMS Notifications for High Level Alarms</span>
                    <div className='button-container'>
                        <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#B7B7B7', width: '220px', height: '30px', marginLeft: '40px', '&:hover': { backgroundColor: '#6b6b6b' } }}>+ Add Phone Number</Button>
                    </div>

                    <span style={{ borderBottom: '1px solid #757575', display: 'inline-block', width: '100%', padding: '2px', marginBottom: '20px', marginTop: '20px' }}>Push Notifications for High Level Alarms</span>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px', fontSize: '14px', marginLeft: '40px' }}>
                        <div style={{ padding: '2px' }}>Enable Push Notifications</div>
                        <select style={{ width: '200px', backgroundColor: '#B7B7B7', color: 'white', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Nunito Sans, sans-serif', marginLeft: '30px', padding: '4px', border: 'none', outline: 'none', borderRadius: '5px' }}>
                            <option value="option1">Disabled</option>
                            <option value="option2">Enabled</option>
                        </select>
                    </div>
                </div>

                <div>
                    <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '30px', marginTop: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                </div>
            </div>

        </div>



    );
};

export default Alarms;