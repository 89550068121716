import React, { useState, useEffect } from 'react';
import SearchInput from './sattImaElement';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useFetchFields } from '../../services/fetchSingeField';
import { useParams } from 'react-router-dom';
import { useToken } from '../../services/useToken';
import NameElement from './sattElementName';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

import ModalTimeline from './timelineModal';
import Timeline from './timelineExample';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

import TextField from '@mui/material/TextField';
import './satellite.css'



//import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';





function mapCropTypeToValue(cropType) {
  const cropTypeMap = {
    'Corn': 10,
    'Wheet': 20,
    'Tomato': 30,
    'None': ''  // Assuming 'None' should be an empty string
  };

  return cropTypeMap[cropType] || '';
}


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.35),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.45),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  marginBottom: 5,

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

const Modal = ({ onClose }) => {
  const initialState = [
    { id: 1, number: 2.5, numberAssigned: 2.5, name: "SetPoint", lowerLimit: 0.5, upperLimit: 5, multi: 10 },
    { id: 2, number: 10, numberAssigned: 2.5, name: "Accelaration", lowerLimit: 0, upperLimit: 6000, multi: 10 },
    { id: 3, number: 10, numberAssigned: 2.5, name: "Decelaration", lowerLimit: 0, upperLimit: 6000, multi: 10 },
    { id: 4, number: 36, numberAssigned: 2.5, name: "freq_min", lowerLimit: 35, upperLimit: 50, multi: 100 },
    { id: 5, number: 50, numberAssigned: 2.5, name: "freq_max", lowerLimit: 36.5, upperLimit: 50, multi: 100 },
    { id: 6, number: 25, numberAssigned: 2.5, name: "nominal current", lowerLimit: 2, upperLimit: 5, multi: 10 },
    { id: 7, number: 25, numberAssigned: 2.5, name: "pressure transmitter", lowerLimit: 2, upperLimit: 5, multi: 10 },
    { id: 8, number: 2.3, numberAssigned: 2.5, name: "start pressure", lowerLimit: 0, upperLimit: 50, multi: 10 },
    // Add more objects for additional boxes
  ];

  const [history, setHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchSeedingHistory = async () => {
    setLoadingHistory(true);
    try {
        const response = await fetch(`https://nabuapplication.com/api/fieldlogs/${dataField.id}/`, {
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        const formattedData = data.map(log => ({
          //title: log.seeding_date,
          cardTitle: log.crop_type,
          cardSubtitle: `Date: ${log.seeding_date}`,
          //cardDetailedText: `Time of Entry: ${log.timestamp}`
      }));
        setHistory(formattedData);
        setLoadingHistory(false);
        setIsModalOpen(true);  // Automatically expand to show the data after fetching
    } catch (error) {
        console.error('Failed to fetch data:', error);
        setLoadingHistory(false);
    }
};

  const [boxes, setBoxes] = useState(initialState);


  //const [selectedDate, setSelectedDate] = useState(null);

  const increaseNumber = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId
          ? {
            ...box,
            number: Math.min(box.number + 0.1, box.upperLimit),
          }
          : box
      )
    );
  };

  const decreaseNumber = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId
          ? {
            ...box,
            number: Math.max(box.number - 0.1, box.lowerLimit),
          }
          : box
      )
    );
  };

  const sendPressure = (itemId) => {
    setBoxes((prevBoxes) =>
      prevBoxes.map((box) =>
        box.id === itemId ? { ...box, numberAssigned: box.number } : box
      )
    );
    handlePostRequestPressure(itemId);
  };

  const handlePostRequestPressure = async (itemId) => {
    try {
      const selectedBox = boxes.find((box) => box.id === itemId);
      if (selectedBox) {
        // Make a POST request to the desired URL
        const response = await fetch('https://nabuapplication.com/api/trigger-lambda/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            device_id: 356116354785580,
            item_id: itemId + 99,
            timer: true,
            value: Math.round(selectedBox.number * selectedBox.multi),
            pre_value: Math.round(selectedBox.number * 100),
          }),
        });

        // Handle the response if necessary
        if (response.ok) {
          // Request was successful
          // Do something
        } else {
          // Request failed
          // Handle the error
        }
      }
    } catch (error) {
      // Handle any network or other errors
    }
  };

  const [updateStatus, setUpdateStatus] = useState(null);


  const handleSubmit = async e => {
    e.preventDefault();

    const updatedData = {
      // Assuming 'id', 'name', etc., are part of dataField and need to be included in the update
      ...dataField,
      name: fieldName ? fieldName : dataField.name,
      size: fieldSize ? fieldSize : dataField.size,
      crop_type: typeSelection, // This should be converted back to the string representation if necessary
      seeding_date: selectedDate ? selectedDate.toISOString().split('T')[0] : null, // Format date as needed by your backend
    };

    try {

      const response = await fetch(`https://nabuapplication.com/api/fields/${dataField.id}/`, {
        method: 'PUT', // or 'POST', depending on your API
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}` // Include the token here
          // Include other headers as required, like authorization tokens
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Success:', responseData);
      setUpdateStatus('success'); // Set status to success on successful update
      // Handle successful response
    } catch (error) {
      console.error('Error:', error);
      setUpdateStatus('error');
      // Handle errors
    }
  }
  const { id } = useParams();
  const token = useToken()
  const { dataField, loadin, erro } = useFetchFields(token, id);
  const [typeSelection, setTypeSelection] = useState(mapCropTypeToValue(dataField?.crop_type));

  useEffect(() => {
    // Update typeSelection if dataField.crop_type changes
    setTypeSelection(mapCropTypeToValue(dataField?.crop_type));
  }, [dataField?.crop_type]);

  const initialDate = dataField?.seeding_date ? new Date(dataField.seeding_date) : null;
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    // Update selectedDate if dataField.seeding_date changes
    setSelectedDate(dataField?.seeding_date ? new Date(dataField.seeding_date) : null);
  }, [dataField?.seeding_date]);

  const [fieldSize, setFieldSize] = useState('');

  const handleSizeChange = (newValue) => {
    setFieldSize(newValue);
  };

  const [fieldName, setFieldName] = useState('');

  const handleNameChange = (newValue) => {
    setFieldName(newValue);
  };


  if (loadin) {
    return <div>Loading...</div>;
  }

  if (erro) {
    return <div>Error: {erro.message}</div>;
  }

  if (!dataField) {
    return <div>No data available</div>;
  }




  return (
    <div className="modal-overlay">
      <div className="modal">
    
        <CancelPresentationRoundedIcon
          onClick={onClose}
          style={{
            color: '#0E8140',
            fontSize: '35px',  // Adjust size as needed
            marginLeft: '90%',
            cursor: 'pointer'  // Optional, changes the mouse cursor to a pointer on hover
          }}
        />
        <div className="modal-content">
          <h2>Field Settings</h2>
          {/*<p>Name: {dataField.name}</p>*/}

          <form onSubmit={handleSubmit} className='custom-width'>
            <div className="modal-content">

              <NameElement initialValue={dataField.name} onNameChange={handleNameChange} style={{ border: '5px solid black' }} />
              <SearchInput initialValue={dataField.size} onValueChange={handleSizeChange} style={{ border: '1px solid black' }} />
              <div className='settingSattDiv'></div>
              <div className='custom-width'>
                <Search>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Crop Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={typeSelection}
                      label="Select the Method"
                      onChange={evt => setTypeSelection(evt.target.value)}
                    >

                      <MenuItem value={"Corn"}>Corn</MenuItem>
                      <MenuItem value={"Wheat"}>Wheat </MenuItem>
                      <MenuItem value={"Tomato"}>Tomato</MenuItem>
                      <MenuItem value={"None"}>
                      <MenuItem value={"Corn"}>Corn</MenuItem>
                      <MenuItem value={"Wheat"}>Wheat </MenuItem>
                      <MenuItem value={"Tomato"}>Tomato</MenuItem>
                      <MenuItem value={"None"}>
                      <MenuItem value={"Corn"}>Corn</MenuItem>
                      <MenuItem value={"Wheat"}>Wheat </MenuItem>
                      <MenuItem value={"Tomato"}>Tomato</MenuItem>
                      <MenuItem value={"None"}>
                      <MenuItem value={"Corn"}>Corn</MenuItem>
                      <MenuItem value={"Wheat"}>Wheat </MenuItem>
                      <MenuItem value={"Tomato"}>Tomato</MenuItem>
                      <MenuItem value={"None"}></MenuItem></MenuItem>
                      </MenuItem>
                        <em>None</em>

                      </MenuItem>
                    </Select>
                  </FormControl>

                </Search>
              </div>

              <div className='settingSattDiv'></div>


              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className='settingsDateCANCELED'>
                  <DatePicker
                    label="Select Seeding Date"
                    value={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="MM/dd/yyyy"
                    maxDate={new Date()} // Set the maximum date to the current date
                    minDate={new Date(new Date().getFullYear(), 0, 1)} // Set the minimum date to the first day of the current year
                  />
                </div>
              </LocalizationProvider>
              <br></br>
           

              {/*<button type="submit">Update</button>*/}
              <button
                type="submit"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer'
                }}
              >
                <SyncRoundedIcon
                  style={{
                    color: '#0E8140',
                    fontSize: '45px', // Adjust size as needed
                  }}
                />
              </button>

              <div style={{ color: updateStatus === 'success' ? 'green' : 'red' }}>
                {updateStatus === 'success' && 'Updated successfully!'}
                {updateStatus === 'error' && 'Error updating data. Please try again.'}
              </div>


            </div>
          </form>
          <br></br>
          <button onClick={fetchSeedingHistory}>
                {loadingHistory ? 'Loading...' : 'See Seeding History'}
            </button>
            <ModalTimeline isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
              {/* 
            <div style={{ width: '100%', height: '600px' }}>
                        <Chrono items={history}
                                mode="HORIZONTAL"
                                slideShow
                                scrollable={{ scrollbar: true }}
                                hideControls={true}
                                className="chrono-without-controls"
                                highlightCardsOnHover= {true} />
                                <Timeline events={history} />
                    </div>*/}
                    <Timeline events={history} />
            </ModalTimeline>


        </div>


      </div>
    </div>
  );
};

export default Modal;