import React from 'react';
import './SectorRow.css';
import LoopIcon from '@mui/icons-material/Loop';


const SectorRow = ({ label }) => {
    return (
        <div className="row">
            <div className="columnSectors">{label}</div>
            <div className="columnSectors"><input type="text" sx={{ backgroundColor: '#D9D9D9', border: 'none', borderRadius: '3px'}}/></div>
            <div className="columnSectors"><input type="text" /></div>
            <div className="columnSectors"><input type="text" /></div>
            <div className="columnSectors"></div>
            <div className="columnSectors">
                <select>
                    <option value="option1">DRY</option>
                    <option value="option2">WET</option>
                </select>
            </div>
            <div className="columnSectors"><input type="text" /></div>
            <div className="columnSectors"></div>
            <div className="columnSectors">
                <select>
                    <option value="option1">DRY</option>
                    <option value="option2">WET</option>
                </select>
            </div>
            <div className="columnSectors"></div>
            <div className="columnSectors"></div>
            <div className="columnSectors">
                <button className="icon-button-sectors">
                    <LoopIcon />
                </button>
            </div>       
            </div>
    );
};

export default SectorRow;
