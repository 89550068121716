import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import sensingIconUrl from '../assets/soil-analysis.png';

// Define icon for the marker
const customIcon = new L.Icon({
  iconUrl: sensingIconUrl, // Replace with your own marker icon URL
  iconSize: [30, 40], // Adjust size for the icon
  iconAnchor: [15, 40], // Adjust to the center of the icon
  popupAnchor: [0, -40], // Adjust popup position
});

// The map container style
const MapWithProps = ({ isMobile, containerStyle, center, markerPosition, markerTitle }) => {
  return (
    <MapContainer
      center={center} // The initial center of the map
      zoom={16} // Adjust zoom level
      style={containerStyle} // Apply the container styles
      scrollWheelZoom={false} // Disable zooming with scroll wheel
    >
      {/* Satellite tile layer */}
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
      />

      {/* Marker with a popup */}
      <Marker position={markerPosition} icon={customIcon}>
        <Popup>
          <div style={{ 
            color: 'white', 
            fontSize: isMobile ? '20px' : '30px', 
            fontWeight: 'bold' 
          }}>
            {markerTitle}
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapWithProps;
