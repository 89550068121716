import React from 'react';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';

const ModalTimeline = ({ isOpen, close, children }) => {
    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0,0,0,0.5)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            zIndex: 1000  // Ensures it is on top of everything else
        }}>
            <div style={{
                position: 'relative', // Position relative for absolute positioning of the close button
                padding: '20px',
                background: '#fff',
                borderRadius: '8px',
                display: 'block',
                maxWidth: '80%', // adjusted for better display of the timeline
                maxHeight: '90%', // larger modal to show more of the timeline
                overflowY: 'auto',
                overflowX: 'hidden' // ensures no horizontal overflow
            }}>
                <button onClick={close} style={{
                    position: 'absolute', // Absolute positioning within the relative container
                    top: '10px', // 10px from the top
                    right: '10px', // 10px from the right
                    padding: '5px',
                    border: 'none', // No border for a cleaner look
                    background: 'transparent', // Transparent background
                    cursor: 'pointer', // Pointer on hover
                    color: '#333', // Icon color
                }}>
                    <CancelPresentationRoundedIcon /> {/* Material UI Icon */}
                </button>
                {children}
            </div>
        </div>
    );
};

export default ModalTimeline;
