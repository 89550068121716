import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, ImageOverlay } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Adjusting the default icon path
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const MyMapComponent = ({ bounds, imageBase64, zoomLevel = 13 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mapStyle = {
    width: '100%',
    height: isMobile ? '300px' : '400px'
  };

  return (
    <MapContainer
      bounds={bounds}
      zoom={zoomLevel}
      minZoom={10}  // Set minimum zoom level
      maxZoom={17}  // Set maximum zoom level
      style={mapStyle}
    >
      {/* Add a fallback Tile Layer in case the main one is unavailable 
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />*/}

      {/* The World Imagery Tile Layer */}
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a>'
      />

      {/* Image Overlay */}
      <ImageOverlay
        url={imageBase64}
        bounds={bounds}
      />
    </MapContainer>
  );
}

export default MyMapComponent;
