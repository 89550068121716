import { useState, useEffect } from 'react';
import { useToken } from '../../services/useToken';
import { useNavigate } from 'react-router-dom';
import { fetchFieldsData } from './fetch_api_fields';
import LoadingSpinner from '../../components/loadingSpiner';


function SattImagesLanding() {
    const token = useToken();
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);  
            try {
                const data = await fetchFieldsData(token);
                setDeviceData(data);

                // Automatically navigate to the page with the smallest `id`
                if (data && data.length > 0) {
                    const smallestIdDevice = data.reduce((prev, curr) => 
                        prev.id < curr.id ? prev : curr
                    );
                    // Navigate to the page with the smallest ID
                    navigate(`/home/sattview/${smallestIdDevice.id}`);
                }
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token, navigate]);

    return (
        <div>
            {loading && <LoadingSpinner />}
            {error && <p>Error: {error.message}</p>}
        </div>
    );
}

export default SattImagesLanding;







/*   Old landing code where user selects their page to direct

import { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useToken } from '../../services/useToken';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { fetchFieldsData } from './fetch_api_fields';

function SattImagesLanding() {
    const token = useToken();
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);  
            try {
                const data = await fetchFieldsData(token);
                setDeviceData(data);
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token]);

    const [typeSelection, setTypeSelection] = useState('');

    const handleSelectionChange = evt => {
        const deviceId = evt.target.value;
        navigate(`/home/sattview/${deviceId}`);
        setTypeSelection(deviceId);
    };

    return (
        <>
            <MediaQuery minWidth={800}>
                <div>
                    <div className='WStitle' style={{ fontSize: '20px', fontFamily: 'Open Sans', fontWeight: '600' }}>Select Your Field</div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl fullWidth sx={{ maxWidth: '1200px' }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}>
                                Select the Field
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={typeSelection}
                                label="Select the Available Data Duration"
                                onChange={handleSelectionChange}
                                sx={{ 
                                    fontSize: '14px', 
                                    fontFamily: 'Open Sans', 
                                    fontWeight: '600',
                                    borderRadius: '3px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '3px'
                                    }
                                }}
                            >
                                {deviceData?.map(device => (
                                    <MenuItem
                                        key={device.id}
                                        value={device.id}
                                        sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}
                                    >
                                        {device.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
                <div className='fieldSelectMobil'>
                    <div className='WStitleMobil' style={{ fontSize: '20px', fontFamily: 'Open Sans', fontWeight: '600' }}>Select Your Field</div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl fullWidth sx={{ maxWidth: '300px' }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}>
                                Select the Field
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={typeSelection}
                                label="Select the Available Data Duration"
                                onChange={handleSelectionChange}
                                sx={{ 
                                    fontSize: '14px', 
                                    fontFamily: 'Open Sans', 
                                    fontWeight: '600',
                                    borderRadius: '3px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '3px'
                                    }
                                }}
                            >
                                {deviceData?.map(device => (
                                    <MenuItem
                                        key={device.id}
                                        value={device.id}
                                        sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}
                                    >
                                        {device.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </MediaQuery>
        </>
    );
}

export default SattImagesLanding;  

*/
