import React from 'react';
import './modal.css'; // Ensure correct CSS path

const Modal = ({ handleClose }) => {
    return (
        <div className='modal display-block'>
            <div className='modal-main'>
                <button className='close-button' onClick={handleClose}>×</button>
                <div className='actions'>
                    <button className='update-button'>Update Graphic</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
