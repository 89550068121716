import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables);

const LineChartRain = () => {
    const { t } = useTranslation();

    const data = {
        labels: [
            t("January"),
            t("February"),
            t("March"),
            t("April"),
            t("May"),
            t("June"),
            t("July")
        ],

        datasets: [
            {
                label: t('Rain'),
                data: [25, 27, 32, 26, 31, 29, 20],
                fill: false,
                backgroundColor: 'rgb(125, 62, 125)',
                borderColor: 'rgb(125, 62, 125)',
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return <Line data={data} options={options} />;
};

export default LineChartRain;