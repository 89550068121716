import React, { useState } from 'react';
import './setup.css';
import PivotNavbar from '../PivotNavbar';
import Button from '@mui/material/Button';

const Setup = () => {
    const [showWarning, setShowWarning] = useState(true);

    const handleCloseWarning = () => {
        setShowWarning(false);
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [permission, setPermission] = useState('option1'); // Default option for dropdown

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handlePermissionChange = (event) => {
        setPermission(event.target.value);
    };

    return (
        <div className='mainSectionTitlePivot'>
            <div className='pivot-navbarContainer'>
                <PivotNavbar />
            </div>

            <div className="pageLayout">
                <div className="contentWrapperSetup">
                    <div className='titleMainPageSetup'>
                        <span className='setup-title'>General Setup</span>
                    </div>

                    <div className='two-column-container'>
                        <div className='setup-item'>
                            <label className='setup-label'>Pivot Name</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Pivot's Center Latitude</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Pivot's Center Longitude</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Pivot's UTC Time Diff</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Wet/Dry Pressure Limit</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Minimum Required Pressure</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Overhang Length</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Total Flow with End Gun/s</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>End Gun Flow</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>End Gun Range</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Water Pressure Delay</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Nominal Water Application (100% Speed)</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Last Tower Speed at 100%</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Enable Auto-start by Pressure</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>Enable Auto-restart by Pressure</label>
                            <input type="text" className='input-box' />
                        </div>

                        <div className='setup-item'>
                            <label className='setup-label'>System of Measurement</label>
                            <input type="text" className='input-box' />
                        </div>
                    </div>

                    <div>
                        <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '30px', marginBottom: '10px', marginTop: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                    </div>
                </div>

                <div className="contentWrapperSetup">
                    <div className='titleMainPageSetup'>
                        <span className='setup-title'>Advanced Setup</span>
                    </div>

                    <div className='advanced-setup-item'>
                        <div style={{ padding: '2px', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Nunito Sans, sans-serif', }}>Enable Water Control</div>
                        <select style={{ width: '200px', backgroundColor: '#B7B7B7', color: 'white', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Nunito Sans, sans-serif', marginLeft: '30px', padding: '4px', border: 'none', outline: 'none', borderRadius: '5px' }}>
                            <option value="option1">None</option>
                            <option value="option2">Wired to Panel</option>
                        </select>
                    </div>
                    {showWarning && (
                        <div className="warning-box" style={{ marginLeft: '3%', marginRight: '3%' }}>
                            <span className="warning-text">Important: After modifying and sending one of these parameters, Please REBOOT iControlRemote in the Maintenance tab</span>
                            <button className="close-button" onClick={handleCloseWarning}>x</button>
                        </div>
                    )}

                    <div>
                        <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '30px', marginTop: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                    </div>

                    <div className='titleMainPageSetup' style={{ marginTop: '5%' }}>
                        <span className='setup-title'>User Management</span>
                    </div>

                    <div className="table-container">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Users</th>
                                    <th>Permission</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>rsimier@aiscosolutions.com (S. Engineer)</td>
                                    <td>
                                        <select className="custom-select">
                                            <option value="option1">Total Control</option>
                                            <option value="option2">Visualization Only</option>
                                            <option value="option3">Disabled</option>
                                        </select>
                                    </td>
                                    <td>
                                        <button className="change-password-button">Change Password</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>andrea.magnano@gmagnano.it (Owner)</td>
                                    <td>
                                        <select className="custom-select">
                                            <option value="option1">Total Control</option>
                                            <option value="option2">Visualization Only</option>
                                            <option value="option3">Disabled</option>
                                        </select>
                                    </td>
                                    <td>
                                        <button className="change-password-button">Change Password</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='titleMainPageSetup' style={{ marginTop: '5%', fontSize: '14px' }}>
                        <span className='setup-title'>+ Add a new operator for the organization</span>
                    </div>


                    <div className="table-container">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Password</th>
                                    <th>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="short-input-box" // Adjusted class for shorter input
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="Enter Email"
                                            style={{ marginTop: '1%' }} // Reduced marginTop
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="password"
                                            className="short-input-box" // Adjusted class for shorter input
                                            value={password}
                                            onChange={handlePasswordChange}
                                            placeholder="Enter Password"
                                            style={{ marginTop: '1%' }} // Reduced marginTop
                                        />
                                    </td>
                                    <td>
                                        <select
                                            className="custom-select"
                                            value={permission}
                                            onChange={handlePermissionChange}
                                            style={{ marginTop: '1%' }} // Reduced marginTop
                                        >
                                            <option value="option1">Disabled</option>
                                            <option value="option2">Total Control</option>
                                            <option value="option3">Control Only</option>
                                            <option value="option4">Visualization Only</option>

                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '30px', marginTop: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Setup;
