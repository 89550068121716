import { useContext, createContext, useState, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('nabu-user'));
    if (user) {
      setAuthData(user);
    }
  }, []);

  const setAuth = newUser => {
    if (newUser) {
      localStorage.setItem('nabu-user', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('nabu-user');
    }
    setAuthData(newUser);
  };

  return (
    <AuthContext.Provider value={{ authData, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
