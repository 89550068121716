import React from 'react';
import './endgun.css';
import PivotNavbar from '../PivotNavbar';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const Endgun = () => {
    return (
        <div className='mainSectionTitlePivot'>
            <div className='pivot-navbarContainer'>
                <PivotNavbar />
            </div>

            <div className="contentWrapperEndgun">

                <div className='titleMainPageEndgun'>
                    <span className='endgun-title'>End Gun</span>
                </div>

                <div className='button-container' style={{ marginLeft: '5%' }}>
                    {/* Buttons with inline styles */}
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'Nunito Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#757575',
                            width: '120px',
                            height: '30px',
                            '&:hover': { backgroundColor: '#6b6b6b' }
                        }}
                    >
                        Panel
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<GpsFixedIcon />}
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'Nunito Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#757575',
                            width: '120px',
                            height: '30px',
                            '&:hover': { backgroundColor: '#6b6b6b' }
                        }}
                    >
                        GPS
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<CheckIcon />}
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'Nunito Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#A6CE39',
                            width: '120px',
                            height: '30px',
                            '&:hover': { backgroundColor: '#94b634' }
                        }}
                    >
                        ON
                    </Button>
                </div>

                <div className='titleMainPageEndgunTable'>
                    <span className='endgun-table-title'>End Gun Table</span>
                    <div className='three-column-table'>
                        <div className='table-cell title-cell'></div>
                        <div className='table-cell title-cell'>Left Angle (º)</div>
                        <div className='table-cell title-cell'>Right Angle (º)</div>
                        <div className='table-cell title-cell'>Sector 1</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                        <div className='table-cell title-cell'>Sector 2</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                        <div className='table-cell title-cell'>Sector 3</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                        <div className='table-cell title-cell'>Sector 4</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                        <div className='table-cell title-cell'>Sector 5</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                        <div className='table-cell title-cell'>Sector 6</div>
                        <div className='table-cell'></div>
                        <div className='table-cell'></div>
                    </div>
                </div>

                <div>
                    <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#A6CE39', width: '200px', height: '30px', marginLeft: '300px', marginTop: '30px', marginBottom: '10px', '&:hover': { backgroundColor: '#94b634' } }}>Validate & Submit</Button>
                </div>
            </div>
        </div>
    );
};

export default Endgun;
