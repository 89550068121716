import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Define icon for the marker
const weatherIcon = new L.Icon({
  iconUrl: 'https://example.com/weather-icon.png', // Replace this with the path to your weather station icon
  iconSize: [30, 40], // Adjust size as needed
  iconAnchor: [15, 40], // Adjust to the center of the icon
  popupAnchor: [0, -40], // Adjust popup position relative to the icon
});

// The map container style
const containerStyle = {
  width: '50vw',
  height: '60vh'
};

const MapWeatherStation = ({ center }) => {
  return (
    <MapContainer
      center={center} // The initial center of the map
      zoom={16} // Adjust zoom level as needed
      style={containerStyle} // Apply the container styles
      scrollWheelZoom={false} // Disable zooming with scroll wheel
    >
      {/* Satellite tile layer */}
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; <a href="https://www.esri.com">Esri</a> contributors'
      />

      {/* Marker with a popup */}
      <Marker position={center} icon={weatherIcon}>
        <Popup>
          <div style={{ color: 'white', fontSize: '16px', fontWeight: 'bold' }}>Weather Station Position</div>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapWeatherStation;
