import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables);

const LineChartPres = () => {
    const { t } = useTranslation();

    const data = {
        labels: [            
        t("January"),
        t("February"),
        t("March"),
        t("April"),
        t("May"),
        t("June"),
        t("July")
    ],
        datasets: [
            {
                label: t('Pressure'),
                data: [25, 27, 32, 26, 31, 29, 20],
                fill: false,
                backgroundColor: 'rgb(255, 153, 51)',
                borderColor: 'rgb(255, 153, 51)',
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return <Line data={data} options={options} />;
};

export default LineChartPres;