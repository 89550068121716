import React, { useState } from 'react';
import { Box, CssBaseline, Typography, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';



import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Satellite from '../satellitePage/satelliteUp';
import SattImagesLanding from '../satellitePage/sattImagesLanding';
import TestLayout from '../../layoutElements/testLayout';


import Navbar from '../../layoutElements/navbar';
import FooterSection from '../../layoutElements/footer';
import Sidebar from '../../layoutElements/SideBarNew'; 
//import Sidebar from '../../layoutElements/SideBarNew';
import MainSection from '../../layoutElements/mainSection';
import { Routes } from 'react-router-dom';
import MainSectionUp from '../../layoutElements/mainSectionUp';
import SensingPageLanding from '../sensingPage/sensingPageLanding';
import SoilSensor from '../soilSensorPage/soilSensorMainPage';
import SimpleTodoBoard from '../todo/todo';
import WeatherStationLora from '../loraweatherstationMain/weatherStationLora';
import FieldPageMain from '../fieldPage/fieldPageMain';
import HomePageMain from '../homePageMain/homePageMainUp';
import SensingPageMain from '../sensingPage/sensingMain';
import IrrigationMain from '../irrigationPage/irrigationMainUp';

import Alarms from '../irrigationPage/pivotNavbarSections/alarms';
import Setup from '../irrigationPage/pivotNavbarSections/setup';
import Modal from '../irrigationPage/pivotNavbarSections/modal';
import Sectors from '../irrigationPage/pivotNavbarSections/sectors';
import Calendar from '../irrigationPage/pivotNavbarSections/calendar';
import Endgun from '../irrigationPage/pivotNavbarSections/endgun';




// Custom theme creation function
const getTheme = (mode) => createTheme({
  palette: {
    mode,
  },
});

const Home = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  const theme = getTheme(darkMode ? 'dark' : 'light');

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {/* Main container with grid layout */}
      <Box sx={{
        display: 'grid',
        gridTemplateRows: '64px 1fr 40px', // Navbar (fixed), Main (dynamic), Footer (fixed)
        height: '100vh', // Full viewport height
        gridTemplateColumns: '1fr',  // Single column layout
      }}>
        {/* Navbar */}
        <Navbar onThemeToggle={handleThemeChange} darkMode={darkMode} />

        {/* Main Content Section with Sidebar */}
        <Box sx={{ display: 'flex', gridRow: '2 / 3', overflow: 'hidden' }}>
          {/* Sidebar */}
          <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              backgroundColor: theme.palette.background.default,
              overflowY: 'auto',  // Allow scrolling for long content
            }}
          >
            <Routes>
              <Route exact path ='/' element={<HomePageMain></HomePageMain>}></Route>
              <Route path ='/test' element={<TestLayout></TestLayout>}></Route>
              <Route exact path ="sattview" element={<SattImagesLanding></SattImagesLanding>}></Route>
              <Route exact path ="sattview/:id" element={<Satellite/>}></Route>
              <Route exact path ="sensing" element={<SensingPageMain></SensingPageMain>}></Route>
              <Route exact path ="sensing/:id" element={<SoilSensor></SoilSensor>}></Route>
              <Route exact path ="todo" element={<SimpleTodoBoard></SimpleTodoBoard>}></Route>
              <Route exact path ="loraweatherstation/:id" element={<WeatherStationLora></WeatherStationLora>}></Route>
              <Route exact path ="field/:id" element={<FieldPageMain></FieldPageMain>}></Route>
              <Route exact path ="irrigation" element={<IrrigationMain></IrrigationMain>}></Route>
              <Route exact path="/irrigation/pivota/alarms" element={<Alarms/>} />
              <Route exact path="/irrigation/pivota/setup" element={<Setup/>} />
              <Route exact path="/irrigation/pivota/sectors" element={<Sectors/>} />
              <Route exact path="/irrigation/pivota/calendar" element={<Calendar/>} />
              <Route exact path="/irrigation/pivota/endgun" element={<Endgun/>} />
          </Routes>
          </Box>
        </Box>

        {/* Footer */}
        <FooterSection sx={{ gridRow: '3 / 4' }} />
      </Box>
    </ThemeProvider>
  );
};

export default Home;








