import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';

function getDayName(dateString) {
    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
}

function getDayNameUp(dateString) {
    const date = new Date(dateString);
    const dayOfMonth = date.getDate(); // Gets the day of the month
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return `${dayOfMonth}-${days[date.getDay()]}`; // Formats the string to "day-monthName"
}


function RainfallBarChart({ data }) {
    // Transform data into an array of objects for Recharts
    const chartData = Object.keys(data).map(day => ({
        date: getDayNameUp(day), // Convert date to day name
        Rainfall: data[day]
    }));

    return (
        <ResponsiveContainer>
        <BarChart
            
            data={chartData}
            margin={{
                top: 20, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
        dataKey="date"
        tick={{ 
            fontSize: 12,
            fill: '#666', // Set the color of ticks (optional)
            angle: -35, // Apply angle
            textAnchor: 'end' // Anchor text after rotation
        }}
        height={60} // Optional: Adjust height to ensure labels don’t overlap or get cut off
    />
            <YAxis label={{ fontSize: 14, value: 'Rainfall (mm)', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
           
            <Bar dataKey="Rainfall" fill="#4682b4" />
        </BarChart>
        </ResponsiveContainer>
    );
}

export default RainfallBarChart;
