import React, { useState } from 'react';
import './alarmLevelsCheckbox.css';

const AlarmLevelsCheckbox = ({ labelText }) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionSelect = (option) => {
        setSelectedOption(prevOption => prevOption === option ? '' : option);
    };

    return (
        <div className="alarm-levels-checkbox">
            <div className="label-text">{labelText}</div>
            <div className="checkbox-options">
                <div
                    className={`checkbox-option ${selectedOption === 'None' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('None')}
                >
                    <div className="checkbox-circle"></div>
                    <div className="checkbox-label">None</div>
                </div>
                <div
                    className={`checkbox-option ${selectedOption === 'Info' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('Info')}
                >
                    <div className="checkbox-circle"></div>
                    <div className="checkbox-label">Info</div>
                </div>
                <div
                    className={`checkbox-option ${selectedOption === 'Low' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('Low')}
                >
                    <div className="checkbox-circle"></div>
                    <div className="checkbox-label">Low</div>
                </div>
                <div
                    className={`checkbox-option ${selectedOption === 'High' ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect('High')}
                >
                    <div className="checkbox-circle"></div>
                    <div className="checkbox-label">High</div>
                </div>
            </div>
        </div>
    );
};

export default AlarmLevelsCheckbox;
