import { useEffect, useState } from 'react';

export function useFarmDataList(token) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);

      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/farms`, { headers });

        // Check if response is OK
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched data:', data);  // Log the raw response data
        setData(data);
        console.log("data", data)

      } catch (e) {
        console.error('Error fetching data:', e);  // Log the error
        setError(e.message || 'Error fetching farm data');
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [token]);

  return { data, loading, error };
}
