import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useToken} from '../../hooks/useToken';
import {useDeviceData} from '../../services/useDeviceData';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import MediaQuery from 'react-responsive';
import LoadingSpinner from '../../components/loadingSpiner';

function SensingPageLanding() {
    const token = useToken();
    const navigate = useNavigate();
    const [typeSelection, setTypeSelection] = useState('');

    const { deviceData, loadingDevice, errorDevice } = useDeviceData(token);

    useEffect(() => {
        console.log("token", token);
        console.log("loading device", loadingDevice);
        console.log("error device", errorDevice);
        console.log("device data", deviceData);
    }, [token, loadingDevice, errorDevice, deviceData]);

    const handleSelectionChange = evt => {
        const deviceId = evt.target.value;
        const selectedDevice = deviceData.find(device => device.id === deviceId);
    
        if (selectedDevice) {
          // Check the device type and navigate accordingly
          if (selectedDevice.device_type === 'moisture') {
            navigate(`/home/sensing/${deviceId}`);
          } else if (selectedDevice.device_type === 'lora_weather_station') {
            navigate(`/home/loraweatherstation/${deviceId}`);
          }
          setTypeSelection(deviceId);
        }
      };

    if (loadingDevice) {
        return <LoadingSpinner />;
      }

    if (errorDevice) {
        return <div>Error: {errorDevice.message}</div>;
    }

    return (
        <>
            <MediaQuery minWidth={800}>
                <div>
                    <div className='WStitle' style={{ fontSize: '20px', fontFamily: 'Open Sans', fontWeight: '600' }}>Select Your Device</div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FormControl fullWidth sx={{ maxWidth: '1200px' }}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}>
                                Select the Field
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={typeSelection}
                                label="Select the Available Data Duration"
                                onChange={handleSelectionChange}
                                sx={{ 
                                    fontSize: '14px', 
                                    fontFamily: 'Open Sans', 
                                    fontWeight: '600',
                                    borderRadius: '3px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '3px'
                                    }
                                }}
                            >
                                {deviceData && deviceData.length > 0 ? (
                                    deviceData.map(device => (
                                        <MenuItem
                                            key={device.id}
                                            value={device.id}
                                            sx={{ fontSize: '14px', fontFamily: 'Open Sans', fontWeight: '600' }}
                                        >
                                            {device.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No devices available</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={799}>
                {/* Similar changes for mobile view */}
            </MediaQuery>
        </>
    );
}

export default SensingPageLanding;