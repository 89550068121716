// useWeatherStationData.js
import { useEffect, useState } from 'react';

export function useWeatherStationData(id, token) {
  const [center, setCenter] = useState({ lat: 44.86204083520033, lng: 7.511102956318369 });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const headers = new Headers({
          'Authorization': `Token ${token}`
        });

        const response = await fetch(`https://nabuapplication.com/api/devices/${id}`, { headers });
        const data = await response.json();
        const latitude = data.coordinates.lat;
        const longitude = data.coordinates.lng;
        setCenter({ lat: latitude, lng: longitude });
        setData(data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (token) {
      fetchData();
    }
  }, [id, token]);

  return { center, data, loading, error };
}



// this hook is used in the weatherStation.js to fetch