export function auth(credentials) {
    return fetch('https://nabuapplication.com/api/authenticate/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error('Authentication failed: ' + resp.statusText);
      }
    })
    .catch(e => {
      console.error('Authentication error:', e);
      throw new Error('Authentication failed: Please, check your credentials');
    });
  }
  
  
  export function register(userData) {
    return fetch('https://nabuapplication.com/api/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    }).then(resp => resp.json())
    .catch(e => {
      console.log(e);
    });
  }
  