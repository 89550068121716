import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ children }) => {
    const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

    useEffect(() => {
        if (timeLeft > 0) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <div style={{
            marginTop: '%',
            marginLeft:'65%',
            marginRight:'5%',
            marginBottom:'5px',
            bottom: '0px', // Distance from the bottom of the screen
            right: '10px',  // Distance from the right side of the screen
            display: 'flex',
            alignItems: 'center',
           
            padding: '0px',
            borderRadius: '8px',
            color: 'black',
        }}>
            <h1 style={{ fontSize: '12px', margin: 0 }}>{formatTime(timeLeft)}</h1>
            {children}
        </div>
    );
};

export default CountdownTimer;
