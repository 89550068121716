import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh'; // Use a Material-UI icon for the refresh
import LightModeIcon from '@mui/icons-material/LightMode';
import { useTranslation } from 'react-i18next';
import solarPanel from '../../assets/sunSolarPanel1.png';

function CustomBoxPump({ id,todaysEnergy, power, battery, totalEnergy, timestamp }) {
  const { t } = useTranslation();

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      {/* Top Section */}
      <div style={{ textAlign: 'center', fontWeight: 'bold', }}>
        {id === 1 ? 'Autostrada' : 'Lago'}
      </div>

      {/* Middle Content */}
      <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <div>Today's Energy: {todaysEnergy}</div>
          <div>Power: {power}</div>
          <div>Battery: {battery}</div>
          <div>Total Energy: {totalEnergy}</div>
        </div>
        <img src={solarPanel} alt="Solar Panel" style={{ width: '50px', height: '50px' }} />
      </div>

      {/* Bottom Section */}
      <div style={{ textAlign: 'right', fontSize: '12px', fontWeight: '600' }}>
        {timestamp}
        <RefreshIcon fontSize="small" style={{ marginLeft: '5px' }} />
      </div>
    </div>
  );
}

export default CustomBoxPump;
